import React from "react";
import { useSelector } from "react-redux";
import Stats from './branches/Stats'
import BranchList from './branches/BranchList'


const Branches = () => { 
     return (
       
      <> 
           <Stats/>
           <BranchList />
     </>
   );
};

export default Branches;
