import React from "react";
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
 import { useSelector } from "react-redux";

 
const Apps = () => {
    var  {userData}  = useSelector((state) => state.auth);
    var  { apps, users }  =  useSelector((state) => state.organization);
    const  array = [1,2,3,4,5,6]
    if(!apps){
        array.map((e)=>{
            
        })
        return  <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
      </div>
    }

    // Only return apps that are products
    const permissions = userData && userData?.permissions
    if (apps !== null && permissions !== null && userData !== null) {
      var permittedApps = []
      apps && apps.map(app => {
        return permissions && permissions.forEach(data => {
          if (data === app.id) {
            permittedApps.push(app)
          }
        })
      })
    }
    
  return (
      <div className=""> 
             <div className="col-lg-6 col-lg-12 order-1">
                <div className="row">
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <Link  to="queue" class="card h-100" style={{height: 42, cursor:"pointer"}}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span class="avatar-initial rounded-circle bg-label-Secondary">Q</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">Queue</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </Link>
                        
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <Link to="bookings" class="card h-100" style={{height: 42, cursor:"pointer"}}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span class="avatar-initial rounded-circle bg-label-Secondary">B</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">Bookings</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </Link>
                        
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <Link to="e-data" class="card h-100" style={{height: 42, cursor:"pointer"}}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span class="avatar-initial rounded-circle bg-label-Secondary">E</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">E data</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </Link>
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <Link to="self-service" class="card h-100" style={{height: 42, cursor:"pointer"}}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span class="avatar-initial rounded-circle bg-label-Secondary">S</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">Self service</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </Link>
                    </div> 
                  
                </div>
            </div>
            <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Fetured</span> </h4>
            <div className="col-lg-6 col-lg-12 order-1">
                <div className="row">
                    
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <Link to="mtc" class="card h-100" style={{height: 42, cursor:"pointer" }}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span  style={{backgroundColor:"#2eadee"}} class="avatar-initial rounded-circle bg-label-Secondary">M</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">MTC</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </Link>
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <div class="card h-100" style={{height: 42, cursor:"pointer" }}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span  style={{backgroundColor:"#01AAAD"}} class="avatar-initial rounded-circle bg-label-Secondary">F</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">FNB</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </div>
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <div class="card h-100" style={{height: 42, cursor:"pointer" }}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span  style={{backgroundColor:"#052b58"}} class="avatar-initial rounded-circle bg-label-Secondary">B</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">Bank WHK</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </div>
                    </div>
                    <div   class="col-md-3 col-lg-4 mb-4">
                        <div class="card h-100" style={{height: 42, cursor:"pointer" }}>
                            <div class="card-header flex-grow-0">
                            <div class="d-flex">
                                <div class="avatar flex-shrink-0  me-3">
                                        <span  style={{backgroundColor:"#9fac5a"}} class="avatar-initial rounded-circle bg-label-Secondary">E</span> 
                                </div>
                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-1">
                                <div class="me-2">
                                    <h5 class="mb-0">EIF</h5>
                                    </div>
                                
                                </div>
                            </div>
                            </div> 
                        </div>
                    </div>
                  
                </div>
            </div>
     
        </div>
   );
};

export default Apps;
