import React  from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import RowActions from './RowActions'
import AddBranch from './forms/AddBranch'
import UpdateBranch from './forms/UpdateBranch'
import moment from 'moment' 
import Constants from "../../../../../utils/Constants";

const BranchList = () => {
  const dispatch = useDispatch()
  const format = "MMMM DD YYYY" 
  var columns = [ "Name", "Location", "created by", "Status", "Created", "Action"];
  var { users }  =  useSelector((state) => state.organization);
  var { branches }  =  useSelector((state) => state.utilities);
   const [branchArray, setBranchArray] =  React.useState([])
  const options = { 
    // filterType: 'checkbox' 
  };
 
   React.useEffect(()=>{ 
    setBranchArray(branches) 
    return () => {
      dispatch({type:'ON_DATA_SUBMITED'})
     }
  }, [branches, users, dispatch])


   return (
       
    <div className="card">  
      <div className="row mx-2 m-4">
        <div className="col-md-2">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                 {branches.length || 0 } Total branches
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-10">
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
          
            <div className="dt-buttons">  
            <button className="dt-button add-new btn btn-primary" tabIndex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAddBranch">
              <span><i className="bx bx-plus me-0 me-sm-2"></i><span className="d-none d-lg-inline-block">Add New Branch</span></span>
            </button> 
          </div>
        </div>
      </div>
    </div> 
    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
 
          <MUIDataTable
          title={"Branches"}
          data={
            branchArray !==null &&  branchArray.map((branch) => { 
              
                let created = `${moment(new Date(branch.created * 1000).toJSON()).format(format) || 'No date'}`
                let user =  users && users?.find((u)=>  u.userId===branch.createdBy )
                var fullname = "System"
                if(user){
                  const { firstname,lastname }  = user
                  fullname = ( firstname   +  ' ' + lastname)
                }
                let  status = Constants.STATUS.find((s) => s.id===branch.branchStatus)
                
                let active = status ?  <span class={`badge bg-label-${status.type}`}>{status.name}</span>  :<span class={`badge bg-label-secondary`}>INACTIVE</span>
                   let location = 
                  <div class="d-flex flex-column">
                    <div   class="text-body text-truncate">
                      <span class="fw-semibold">{branch.country }, { branch.region}</span>
                    </div>
                     <small class="text-muted">{branch.address}</small>
                  </div> 
              return  [branch.name, location, fullname, active,created,<RowActions branchId={branch.id}/>]                
           }) 

          }
          columns={columns}
          options={options}
          fixedHeader={true} 
          /> 
      </div>
    </div>

        <UpdateBranch/>
        <AddBranch/>

    </div>
   );
};



export default BranchList
 