import React from "react";
import { useSelector, useDispatch } from "react-redux";
import  { updateForm }  from "../../../../../../store/actions/utilities/device";
import Alert from "../../../../../common/Alert"
import Constants from "../../../../../../utils/Constants";
 
 
const UpdateDevice = () => {

    const dispatch = useDispatch()
    var {   layouts, apps }  =  useSelector((state) => state.organization);
    var { branches, device }  =  useSelector((state) => state.utilities);   
    const [ isloading, setIsloading ] =  React.useState(false)
    var appsData = []
    if(apps!==null){ appsData = (apps && apps.filter(element => element.product && element.productType === 'Customer Experience' &&  element.active )) || [] }

    const updateDevice = () => {  
      setIsloading(true)
     dispatch(updateForm(device)).then((res) => { 
         dispatch({type:'ON_UTILITY_SUBMITED'})
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Device successfully updated' }})
         setTimeout(() => {
          document.getElementById("closeBranchUpdate").click();
         dispatch({type:'HIDE_SWEET_ALERT'})
         setIsloading(false)  
         }, 2000); 
         })
       .catch((e) => {

         dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error updating device' }})
         setTimeout(() => {
           dispatch({type:'HIDE_SWEET_ALERT'})  
           setIsloading(false)  
          }, 4000); 
       }); 
     }
    
    const onChange = (key, val) => dispatch({type:'ON_DEVICE_KEY_UPDATED', payload:{ key, val }})
  
    const validateDevice = (e) => {
      e.preventDefault()
      let  { name, branchId , description, solutionId, deviceStatus } = device
      let payload = {name, branchId , description, solutionId, deviceStatus }
      const isEmpty = Object.values(payload).every(value => !!value && value?.trim() !== '');
        if(!isEmpty){
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'All fields are required' }})
         setTimeout(() => {
           dispatch({type:'HIDE_SWEET_ALERT'}) 
         }, 3000);
      }
      else {
           dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'secondary', text:'Updating device' }})
            updateDevice()
       }
   }

   var qmStatus = device.solutionId === "h5tCHPaaoGvc7b84BE5u"
   ?  
  <label class="switch">
      <input type="checkbox"  checked={device.queueManagement} onChange={(e)=>onChange('queueManagement', e.target.value)} class="switch-input"/>
      <span class="switch-toggle-slider">
        <span class="switch-on"></span>
        <span class="switch-off"></span>
      </span>
      <span class="switch-label">Does this device has QM/Feedback?</span>
    </label>
    : null

    var dsLayout = device.solutionId === "h5tCHPaaoGvc7b84BE5u"
    ? 
      <select className="form-select" value={device.layoutId}  onChange={(e) =>  onChange("layoutId", e.target.value)} >
         {(layouts && layouts?.map( ({name, id }) => <option className="" key={id} value={id}>{name}</option> )) || "Loading layouts"}
      </select>
     : null


   
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasUpdateDevice" aria-labelledby="offcanvasAddUserLabel"  >
    <div className="offcanvas-header">
      <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Device</h5>
      <button type="button" id="closeBranchUpdate" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body mx-0 flex-grow-0">
     <Alert/>
      <form className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewBranchForm" onSubmit={(e)=>validateDevice(e)} noValidate="novalidate">
        <div className="mb-3 fv-plugins-icon-container">
          <label className="form-label" htmlFor="add-user-fullname">Name</label>
          <input value={device.name} required={true} type="text" className="form-control" id="branch_name" onChange={(e)=>onChange('name', e.target.value)} placeholder="Production" name="userFullname" aria-label="John Doe"/>
          <div className="fv-plugins-message-container invalid-feedback"></div>
        </div> 
        <div className="mb-3">
          <label className="form-label" htmlFor="address">Description</label>
          <textarea required={true} value={device.description} name="address"  onChange={(e)=>onChange('description', e.target.value)}  class="form-control" id="address" rows="2" placeholder="1456, Mall Road"></textarea>
         </div>
        
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Branch</label>
          <select id="user-plan" value={device.branchId}  onChange={(e)=>onChange('branchId', e.target.value)} className="form-select">
           {
              branches.map( ({name, id }) => <option className="" key={id} value={id}>{name}</option> )
            } 
          </select> 
        </div> 
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Solution type</label>
          <select id="user-plan" value={device.solutionId}  onChange={(e)=>onChange('solutionId', e.target.value)} className="form-select">
             {appsData?.map( ({name, id }) => <option className="" key={id} value={id}>{name}</option> )} 
          </select> 
        </div> 
        <div className="mb-4">
          {
            qmStatus
          }
        </div>
        <div className="mb-4">
          {
            dsLayout
          }
        </div>
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Status</label>
          <select id="user-plan"  selected={device.deviceStatus} onChange={(e)=>onChange('deviceStatus', e.target.value)} className="form-select">
          {
              Constants.STATUS.map((s )=>{ 
                return  <option  value={s.id} >{s.name}</option>
              })
            } 
          </select> 
        </div>
        <button type="submit"
            style={{  alignItems:"center", justifyContent:"space-between"}} 
            disabled={
              isloading ||
                device.name === '' || 
                device.description === '' || 
                device.branchId === '' || 
                device.solutionId === '' || 
                device.deviceStatus === ''  
            } 
            className="btn btn-primary me-sm-3 me-1 data-submit">Submit &nbsp;
            {isloading && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
        </button>
        <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
      <div></div><input type="hidden"/>
      </form>
    </div>
  </div>
  )
}

export default UpdateDevice