import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "./helpers/history";

// Authentication
import Login from "./components/authentication/Login";
 
// Layouts
import Layout from "./pages/Layout";

// Pages
import Apps from "./components/apps/Apps"; 
import ActiveApp from './pages/ActiveApp'
import Admin from './pages/Admin'  
import QueueManagement from './pages/QueueManagement' 
import Bookings from './pages/Bookings'

import UpdateOrganization from './pages/UpdateOrganization'

import AddAccount from './pages/AddAccount'
import UpdateAccount from './pages/UpdateAccount' 
import VerifyQr from './pages/VerifyQr'  





// Handle errors
import PageNotFound from "./pages/PageNotFound";



const ProtectedRoute = ({ component: Component, stores, ...rest }) => (
  (<Route
 {...rest} render={(props) => (
   stores.auth.isLoggedIn
     ? <Component { ...props } />
     : <Redirect to={{ pathname: '/login', state: { from: props.history.location } }} />)}
/>)
)
const App = () => {
  var stores = useSelector((state) => state) 
  return (
    <Router history={history}> 
           <Switch>
            <Route exact path='/qrcode/:ticketId' stores={stores} component={VerifyQr} />
            <Route exact path={"/login"} component={Login} />
             {/* <Route exact path={"/forms"} component={Forms} /> 
            <Route exact path={"/queue"} component={QMS} />  */}
            {/* <Route exact path={"/customer-experience"} component={CXS} />   */}
          {/* <Route exact path={"/e-data"} component={EDATA} />  */}
          {/* <Route exact path={"/self-service"} component={SSK} />  */}
          {/* <Route exact path={"/chat"} component={Chat} />  */}


            <Layout>
              <ProtectedRoute exact path={ "/"} stores={stores} component={Login} />
              {/* <ProtectedRoute exact path={"/"} component={Layout} />  */}

             

              {/* MAIN */}
              <ProtectedRoute exact path='/apps/:pageId' stores={stores} component={ActiveApp} />
              <ProtectedRoute exact path={"/apps"} stores={stores} component={Apps} />
              <ProtectedRoute exact path='/admin/:pageId' stores={stores} component={Admin} /> 
              <ProtectedRoute exact path='/queue/:pageId' stores={stores} component={QueueManagement} />
              <ProtectedRoute exact path='/queue' stores={stores} component={QueueManagement} />
              <ProtectedRoute exact path='/Bookings/:pageId' stores={stores} component={Bookings} />
              <ProtectedRoute exact path='/Bookings' stores={stores} component={Bookings} />

              {/* ORGANIZATION */}
              <ProtectedRoute exact path='/settings/:id' stores={stores} component={UpdateOrganization} />  

              {/* ACCOUNT */}
              <ProtectedRoute exact path='/add-user' stores={stores} component={AddAccount} /> 
              <ProtectedRoute exact path='/profile/:pageId' stores={stores} component={UpdateAccount} /> 
             

            
              {/* Page not found */}
            </Layout>
              <Route path='/404' component={PageNotFound} /> 
              <Redirect from='*' to='/404' />
          </Switch>
      </Router>
  );
};

export default App;
