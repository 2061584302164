import React from "react";
import Stats from './departments/Stats'
import DepartmentList from './departments/DepartmentList'

const Departments = () => { 
     return (
      <> 
         <Stats/>
         <DepartmentList />
     </>
   );
};

export default Departments;
