import React from "react";
import { useSelector } from "react-redux";
 
const UserStatistics = (  ) => {
  var { users }  =  useSelector((state) => state.organization);
   var  active  =  users !== null ? users?.filter(user=>{ return user.userStatus === '1'}).length : 0
  var  inActive  =  users !== null ? users?.filter(user=>{ return user.userStatus === '2'}).length : 0
  var  pending  =  users !== null ? users?.filter(user=>{ return user.userStatus === '3'}).length : 0
  var  suspended  =  users !== null ? users?.filter(user=>{ return user.userStatus === '4'}).length : 0
    return (
       
    <div class="row g-4 mb-4">
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Total users</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{users.length}</h4>
                {/* <small class="text-success">(+29%)</small> */}
              <small><small class="text-success"> Online</small> (+29%)</small>
              </div>
            </div>
            <span class="badge bg-label-primary rounded p-2">
              <i class="bx bx-user bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Offline Users</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{inActive}</h4>
                {/* <small class="text-success">(+18%)</small> */}
              </div>
              {/* <small>Last week analytics </small> */}
            </div>
            <span class="badge bg-label-danger rounded p-2">
              <i class="bx bx-user-plus bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Active Users</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{pending}</h4>
                {/* <small class="text-danger">(-14%)</small> */}
              </div>
              {/* <small>Last week analytics</small> */}
            </div>
            <span class="badge bg-label-success rounded p-2">
              <i class="bx bx-group bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Suspended Users</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{suspended}</h4>
                {/* <small class="text-success">(+42%)</small> */}
              </div>
              {/* <small>Last week analytics</small> */}
            </div>
            <span class="badge bg-label-warning rounded p-2">
              <i class="bx bx-user-voice bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
   );
};

export default UserStatistics;
