import React from "react";
import Users from "../components/apps/admin/utilities/Users" 
import Branches from "../components/apps/admin/utilities/Branches"  
import Departments from "../components/apps/admin/utilities/Departments"
import Devices from "../components/apps/admin/utilities/Devices" 
import Storage from "../components/apps/admin/utilities/Storage"
import AddMedia from "../components/apps/admin/utilities/storage/forms/AddMedia";


 
const Admin =(props) => {

  var { match } = props
  var components = [  
    { name: "users",     page:Users},
    { name: "branches",     page:Branches},
    { name: "departments",     page:Departments},
    { name: "departments",     page:Devices},
    { name: "storage",     page:Storage},
    { name: "add_media",     page:Devices}
  ]
  
  var component = components.find(item => item.name === match.params.pageId);
  document.title = "Admin | " + component.name || 'users'
  let DynamicComponent
    if(component!=null ) {
      DynamicComponent =  component.page
  }
  else {
      DynamicComponent = Users
  }
    
  return <DynamicComponent/> 

}
 
export default Admin
