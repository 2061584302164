import { doc, addDoc,  onSnapshot, updateDoc, collection, deleteDoc} from "firebase/firestore";
import { getStorage, ref,  getMetadata, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db } from "../../../utils/firebase";
 
const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()
const { v1: uuidv1 } = require('uuid');
  
  
export const updateProfilePictue = (payload, user) => async (dispatch, getState) => { 
  dispatch({ type: 'ON_DATA_SUBMITING' });
  
  var {  organization, auth } = getState()
   const organizationObject = organization.organization
  
     var  originFileOb  =payload?.name
    var ext = originFileOb.split('.').pop()
    var fileName = uuidv1() + "." + ext
    
    /** @type {any} */
    const metadata = {
      contentType: payload.type
    };
    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageFunc = getStorage();
    const bucketName = 'rootStorage/uploads/'
    const storageRef = ref(storageFunc, bucketName + fileName);
    const uploadTask = uploadBytesResumable(storageRef, payload, metadata);
    
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + parseInt(progress) + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
          case 'running':
            console.log('Upload is running');
            break;
          }
    }, 
    (error) => {
      // console.log('Upload error', error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
          case 'storage/canceled':
            // User canceled the upload
            break;
            
            // ...
            
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
            }
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((mediaUrl) => {
              getMetadata(storageRef)
              .then(async(metadata) => { 
                var contentType = metadata.contentType
                var fullPath = metadata.fullPath
                var type = metadata.type
                var size = metadata.size
                var filename = metadata.name
                var data = { contentType, fullPath, type, size}
                var now = dayjs().format()
                var created = dayjs(now).unix()
                const uuid = uuidv1();
                var extras = { status:0, indexed:false}
                var payload = { title:user?.firstname + " " + user?.lastname, description:user, data, deleted:false, filename, mediaUrl, createdBy:auth.user.uid, extras, organizationId:organizationObject.id, created, uuid }
                try{
                  await addDoc(collection(db, "utilities/data/storage"),  { ...payload } )
                  .then(
                    async(res) =>{
                      dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'successs', text:'Image uploaded' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                  }, 4000); 
  

                var { auth, organization  } = getState() 
                user['admin'] = parseInt(user?.role) === 1
                var now = dayjs().format()
                var updated = dayjs(now).unix()
                
                const organizationId = organization?.organization?.id
                let updatedBy = auth?.userData?.userId 
                var updatedUser = { ...user, organizationId, filename, profileUrl:mediaUrl, updatedBy}
                var userPromise
                const taskDocRef = doc(db,  "users", user?.id) 
                try{ 
                   await updateDoc(taskDocRef, { ...updatedUser, updated }).then(()=>{  })

                  onSnapshot(doc(db,  "users", user?.id), async (doc) => {
                    dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'User updated' }})
                      setTimeout(() => {
                        dispatch({type:'HIDE_SWEET_ALERT'})
                       }, 4000); 
                      return doc
              });
                 }
                catch (err) {
                  } 
              })
              .catch((error) => { 
               }); 
            } catch (err) { 
              return  err
            }

            })

           .catch((error) => {
              // Uh-oh, an error occurred!
           });

      });
    }
  );

  
 

};




 





 