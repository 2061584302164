import React from "react";
import { useParams, Link, NavLink} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import {  ackTicket } from "../store/actions/queueManagement";

const VerifyQr = (props) => { 

  const dispatch = useDispatch()
   var { match } = props    
 

  React.useState(()=>{
    if(match){
      dispatch(ackTicket(match.params.ticketId) )
    }
  },[match])


  return (
    <div className="container-xxl container-p-y">
      <div className="misc-wrapper" style={{textAlign:'center'}}>
        <h2 className="mb-2 mx-2">Retrieving QR code</h2>
        <p className="mb-4 mx-2"> Please wait.... <span role="img" aria-label=''> 😖</span> </p>
        {1 && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
      </div>
    </div>
   );
};

export default VerifyQr;
