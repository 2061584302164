import React from "react";
import Stats from './devices/Stats'
import DeviceList from './devices/DeviceList'

const Devices = () => { 
     return (
      <> 
         <Stats/>
         <DeviceList />
     </>
   );
};

export default Devices;
