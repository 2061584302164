import React from "react";
import { Redirect } from 'react-router-dom'
import { useSelector } from "react-redux";

 
// import DataCollection from "./DataCollection" 
import QueueManagement from "./QueueManagement"
 import ComingSoon from "./ComingSoon";
 
 

const ActiveAppComponent =(props) => {

     var { match } = props    
      var components =  [ 
      { name: "messager", page:ComingSoon, appId:''},
    //   { name: "e-data", page:DataCollection, appId:''},
      { name: "self-service", page:ComingSoon, appId:''},
      { name: "digital-signage", page:ComingSoon, appId:''},
      { name: "queue-management", page:QueueManagement, appId:''},
    ]
 
    var component = components.find(item => item.name === match.params.pageId);
    let DynamicComponent
     if(component!=null ) {
        DynamicComponent =  component.page
    }
    else {
         DynamicComponent = ComingSoon
    }
     
    return   <DynamicComponent/>
         
  } 

export default ActiveAppComponent
