import React, { useState}  from "react";
import { useSelector, useDispatch } from "react-redux"; 
import {  getServices, getBranches, sendReview} from "../../../../store/actions/queueManagement";
import { Link } from "react-router-dom";
 import Alert from "../../../common/Alert"
 import { Calendar, momentLocalizer } from 'react-big-calendar'
 import "react-big-calendar/lib/css/react-big-calendar.css";
 
 import moment from 'moment' 
 import events from "./events";
 const localizer = momentLocalizer(moment)

 const CreateAppintment = (props) => {
  const dispatch = useDispatch()
    var {  users, organizations, branches, services, currentOrganizationId }  =  useSelector((state) => state.organization);

  var {  popup }  =  useSelector((state) => state.appReducer);
    var { ticket } = useSelector((state) => state.queueManagement); 
  const [ isLoading, setIsloading ] = React.useState(false)
 
  React.useEffect(()=> { 
   
   if(currentOrganizationId){ 
      
      dispatch(getBranches(currentOrganizationId)) 
    dispatch(getServices(currentOrganizationId))
   }
    return () => {
    dispatch({type:'ON_UTILITY_SUBMITED'})
   }
}, [    currentOrganizationId ])

 
 
  const [rating, setRating] = useState(0)
  const [comments, setComments] = useState('')
  

  // Catch Rating value
  const onCalendarChange = ( val) =>  console.log( val) 
  const onBookingUpdate = ( val) =>  console.log( val)  


   
  const handleSubmit = async ()  =>{
           setIsloading(true)
           const payload = { rating, comments, organizationId:ticket?.organizationId, ticketId:ticket?.id }
           await dispatch(sendReview(payload)).then((res) => { 
             dispatch({type:'ON_BOOKING_CLEAR'}) 
             setRating(0)
             setComments('')
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Review submitted' }})
              setTimeout(() => {
                setIsloading(false)  
            dispatch({type:'HIDE_SWEET_ALERT'})
            }, 3000) 
            })
          .catch((e) => { 
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error submitting review' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false)   
          }, 4000);  
        }); 
  }

  const clearTicket = () => {
    dispatch({type:'ON_BOOKING_CLEAR'}) 
  }

 

    return (
       
    <div className="card">  
      <div className="row mx-2 m-4 mb-0">
        <div className="col-md-8">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <h3>
               Appointment {ticket?.ticketNumber}
              </h3>
            </div>
          </div>
 
        <Alert/>
        </div> 
    </div> 
    

    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
      <div className="row mx-2 m-4">
      <div className="mb-4">
        
        {
           ticket?.number ? 
           <div className="m-4">
          <div class="alert alert-success alert-dismissible" role="alert">
              <h6 class="alert-heading d-flex align-items-center fw-bold mb-1">Your ticket number: {ticket?.number}</h6>
              <p class="mb-0">You will recieve a notification shortly</p>
 
                </div> 
               <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <Link  to={"/queue"} className="btn btn-primary " onClick={clearTicket}  
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>  View bookings </span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </Link>
          </div>
      </div>
      : null
 
      }

      </div>
      <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Organization to join</label>
            <select id="user-plan"   onChange={(e)=>onBookingUpdate('updateOrganizationId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select organization</option>
            {
                organizations && organizations?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Branch</label>
            <select id="user-plan"    onChange={(e)=>onBookingUpdate('branchId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select branch</option>
            {
                branches && branches?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Service</label>
            <select id="user-plan"    onChange={(e)=>onBookingUpdate('serviceId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select service</option>
            {
                services && services?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 
      <div className="mb-4">
      <div>
    <Calendar
      localizer={localizer}
      // disabledTimeslots={}
      // maxTimeslots={300}
      events={events}
      onNavigate={date =>onCalendarChange ({ date })}
      startAccessor="start"
      endAccessor="end"
      startDateInputProps
      onSelectEvent={(event) => onCalendarChange(event)}
        onSelectSlot={(event) => onCalendarChange(event)}
        selectable
        endDateInputProps
      style={{ height: 500 }}
    />
  </div>
      </div>
      {
         ticket?.number ? null :
         <> 
 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Booking Note</label>
            <textarea required={true}  value={comments}  name="address"  onChange={(e)=>setComments(e.target.value)}  class="form-control" id="address" rows="5" placeholder="I fully recomment this, we need free wifi though"></textarea>
              
          </div> 
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <button className="btn btn-primary " onClick={handleSubmit} disabled={
                  isLoading || 
                    comments === ""  
                   } 
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>{isLoading ? "Sending feedback, please wait" : "Save"}</span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
 
                </div> 
         </>
      } 
          
     </div> 
  
 
      </div>
    </div>
 

    </div>
   );
};



export default CreateAppintment
 