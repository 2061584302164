import React from "react";
import {  useDispatch } from "react-redux";
import  { deleteForm }  from "../../../../../store/actions/utilities/media";

const RowActions = ({storageId}) => {
  const dispatch = useDispatch()
  const deleteRow = () => dispatch(deleteForm(storageId)) 
 
     
  return (
    <div class="d-inline-block text-nowrap"> 
      <button onClick={deleteRow} class="btn btn-sm  btn-icon delete-record">
        <i style={{fontSize: 16, color:'red'}} class="bx bx-trash  "></i>
      </button> 
      <div class="dropdown-menu dropdown-menu-end m-0" style={{}}>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("View...",storageId)}  class="dropdown-item">View</div>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("Roproting media...",storageId)} class="dropdown-item">Report</div>
      </div>
    </div>
  )
}

 
export default  RowActions
 