import React from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import RowActions from './RowActions'
import moment from 'moment' 
import { Link } from "react-router-dom";
import Constants from "../../../../../utils/Constants";

const format = "MMMM DD YYYY" 
const options = { 
  filterType: 'checkbox'
 };
var columns = ["Full name", "email",  "role", "branch", "department", "Status", "Created", "Action"];

 
const roles = [
  {id:'1', name: 'SUPER ADMIN', avatar:<span class="badge badge-center rounded-pill bg-label-secondary w-px-30 h-px-30 me-2"><i class="bx bx-mobile-alt bx-xs"></i></span>},
  {id:'2', name: 'ADMIN', avatar:<span class="badge badge-center rounded-pill bg-label-success w-px-30 h-px-30 me-2"><i class="bx bx-cog bx-xs"></i></span>},
  {id:'3', name: 'BRANCH AMNAGER', avatar:<span class="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2"><i class="bx bx-pie-chart-alt bx-xs"></i></span>},
  {id:'4', name: 'SUPERVISOR', avatar:<span class="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2"><i class="bx bx-user bx-xs"></i></span>},
  {id:'5', name: 'AGENT/CONSULTANT', avatar:<span class="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2"><i class="bx bx-user bx-xs"></i></span>},
] 
const UserList = (  ) => {
  
  var [ rows, setRows] = React.useState([])
  var { users }  =  useSelector((state) => state.organization);
  var {  branches, departments }  =  useSelector((state) => state.utilities);
  React.useEffect(()=>{
      const unsub = () => {
        var usersArray = []; 
        users && users.map((user, i) => { 
              let row = []
              let { firstname, lastname } = user
              let fullname = `${firstname + ' ' + lastname}`
              // let active  = user.active ? 'Active' : 'Supspended'
              let created = `${moment(new Date(user.created * 1000).toJSON()).format(format) || 'No date'}`
              let role = roles.find((role)=>role.id === user.role)
              var department = departments && departments?.find((d)=>  d.id===user.departmentId )
               let branchName = 'No branch'
              let branch = branches && branches?.find((b)=>  b.id===user.branchId )
              if(branch && branch.id){ branchName = branch?.name }
              if(firstname &&  lastname) {fullname = `${firstname + ' ' + lastname}`}
              let  status = Constants.STATUS.find((s) => s.id===user.userStatus)
                let active = status ?  <span class={`badge bg-label-${status.type}`}>{status.name}</span>  :<span class={`badge bg-label-secondary`}>INACTIVE</span>
              row.push(fullname)
              row.push(user?.email || 'None')
              row.push(<div> {role?.avatar } {role?.name }</div>)
              row.push(branchName)
              row.push( department?.name || 'No department' )
              row.push(active)
              row.push(created)
              row.push(<RowActions userId={user.userId}/>) 
              usersArray.push(row)
           }) 
        setRows(usersArray) 
        
      }
      return unsub()
  },[users, branches])

  if(!users){
      return <div data-i18n=" ">Loading user account...</div>
  }

 

   return (
       
    <div className="card">  
                      <div className="row mx-2 m-4">
                        <div className="col-md-2">
                          <div className="me-3">
                            <div className="dataTables_length" id="DataTables_Table_0_length">
                              <label>{users?.length || 0} Total users</label>
                            </div>
                          </div>
                        </div>
                      <div className="col-md-10">
                        <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
                       
                          <div className="dt-buttons">  
                          <Link to={`/add-user/`} className="dt-button add-new btn btn-primary" tabIndex="0" type="button">
                            <span><i className="bx bx-plus me-0 me-sm-2"></i><span className="d-none d-lg-inline-block">Add New User</span></span>
                          </Link> 
                        </div>
                      </div>
                    </div>
                  </div> 
                <div className="card-datatable table-responsive">
                  <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer"> 
                     <MUIDataTable
                      title={"Users"}
                      data={rows}
                      columns={columns}
                      options={options}
                      fixedHeader={true}
                       onCellClick={data => {
                        console.log(data);
                      }}
                     /> 
                  </div>
                </div>


                 <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                  <div className="offcanvas-header">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Add User</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body mx-0 flex-grow-0">
                    <form className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewUserForm" onsubmit="return false" noValidate="novalidate">
                      <div className="mb-3 fv-plugins-icon-container">
                        <label className="form-label" htmlFor="add-user-fullname">Full Name</label>
                        <input type="text" className="form-control" id="add-user-fullname" placeholder="John Doe" name="userFullname" aria-label="John Doe"/>
                      <div className="fv-plugins-message-container invalid-feedback"></div></div>
                      <div className="mb-3 fv-plugins-icon-container">
                        <label className="form-label" htmlFor="add-user-email">Email</label>
                        <input type="text" id="add-user-email" className="form-control" placeholder="john.doe@example.com" aria-label="john.doe@example.com" name="userEmail"/>
                      <div className="fv-plugins-message-container invalid-feedback"></div></div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="add-user-contact">Contact</label>
                        <input type="text" id="add-user-contact" className="form-control phone-mask" placeholder="+1 (609) 988-44-11" aria-label="john.doe@example.com" name="userContact"/>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="add-user-company">Company</label>
                        <input type="text" id="add-user-company" className="form-control" placeholder="Web Developer" aria-label="jdoe1" name="companyName"/>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="country">Country</label>
                        <div className="position-relative"><select id="country" className="select2 form-select select2-hidden-accessible" data-select2-id="country" tabIndex="-1" aria-hidden="true">
                          <option value="" data-select2-id="2">Select</option>
                          <option value="Australia">Australia</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Canada">Canada</option>
                          <option value="China">China</option>
                          <option value="France">France</option>
                          <option value="Germany">Germany</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Japan">Japan</option>
                          <option value="Korea">Korea, Republic of</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Russia">Russian Federation</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">United Arab Emirates</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                        </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="1" style={{width: 352}}>
                          <span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-country-container">
                            <span className="select2-selection__rendered" id="select2-country-container" role="textbox" aria-readonly="true">
                            <span className="select2-selection__placeholder">Select Country</span></span>
                            <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span></div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="user-role">User Role</label>
                        <select id="user-role" className="form-select">
                          <option value="subscriber">Subscriber</option>
                          <option value="editor">Editor</option>
                          <option value="maintainer">Maintainer</option>
                          <option value="author">Author</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <label className="form-label" htmlFor="user-plan">Select Plan</label>
                        <select id="user-plan" className="form-select">
                          <option value="basic">Basic</option>
                          <option value="enterprise">Enterprise</option>
                          <option value="company">Company</option>
                          <option value="team">Team</option>
                        </select>
                      </div>
                      <button type="submit" className="btn btn-primary me-sm-3 me-1 data-submit">Submit</button>
                      <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                    <div></div><input type="hidden"/>
                    </form>
                  </div>
                </div>


              </div>
   );
};

export default UserList;
