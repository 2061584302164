import React from "react";
 
const PageNotFound = () => { 
  return (
    <div className="container-xxl container-p-y">
      <div className="misc-wrapper" style={{textAlign:'center'}}>
        <h2 className="mb-2 mx-2">Page Not Found :(</h2>
        <p className="mb-4 mx-2">Oops! <span role="img" aria-label=''> 😖</span> The requested URL was not found on this server.</p>
        <a href="/" className="btn btn-primary">Back to home</a>
        <div className="mt-3">
          <img src={require("../assets/img/illustrations/page-misc-error-light.png")} alt="page-misc-error-light" width="500" className="img-fluid" data-app-dark-img="illustrations/page-misc-error-dark.png" data-app-light-img={require("../assets/img/illustrations/page-misc-error-light.png")}/>
        </div>
      </div>
    </div>
   );
};

export default PageNotFound;
