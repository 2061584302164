import { doc, addDoc,  onSnapshot, updateDoc, collection, deleteDoc} from "firebase/firestore";
import { db } from "../../../utils/firebase";
const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()
 
export const createForm = (payload) => async (dispatch, getState) => { 
      dispatch({ type: 'ON_DATA_SUBMITING' });
      
      var {  organization, auth } = getState()  
      const organizationObject = organization.organization
      payload = { ...payload, createdBy:auth.user.uid, organizationId:organizationObject.id, created }
      if(payload.solutionId!=="h5tCHPaaoGvc7b84BE5u") {
          payload.queueManagement=false
          payload.layoutId=''
       }
         try{
        await addDoc(collection(db, "utilities/data/devices"),  { ...payload } )
      .then((res) =>{ 
          return  res })
      .catch((error) => { 
         return  error
      }); 
    } catch (err) { 
       return  err
    }
  };
  
export const updateForm = (payload) => async (dispatch) => {
  dispatch({ type: 'ON_DATA_SUBMITING' });
  if(payload.solutionId!=="h5tCHPaaoGvc7b84BE5u") {
    payload.queueManagement=false
    payload.layoutId=''
 }
  const taskDocRef = doc(db,  "utilities/data/devices", payload?.id)
     try {
      await updateDoc(taskDocRef, { ...payload, updated })
      onSnapshot(doc(db,  "utilities/data/devices", payload?.id), async (doc) => {
         return doc
  });
    } catch (err) {
  
      return err
  }
};

export const updateSettings = (payload) => async (dispatch) => { 
  console.log("payload settings....", payload);
  const taskDocRef = doc(db,  "utilities/data/devices", payload?.id)
  
     try {
      await updateDoc(taskDocRef, { ...payload, updated })
      onSnapshot(doc(db,  "utilities/data/devices", payload?.id), async (doc) => {
         return doc
  });
    } catch (err) {
  
      return err
  }
};
         
export const deleteForm = (id) => async () => {
  if(id){
    await deleteDoc(doc(db, "utilities/data/devices", id))
    
    .then((res)=>{ console.log(res);}).catch( error => { } )
  };

  }

 