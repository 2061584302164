import React from "react";
import {  useDispatch } from "react-redux";
import  { deleteForm }  from "../../../../../store/actions/utilities/department";
 
const RowActions = ({departmentId}) => {
  const dispatch = useDispatch()
   const deleteRow = () => dispatch(deleteForm(departmentId)) 
   const updateRow = () => dispatch({type:'ON_DEPARTMENT_UPDATE', payload:departmentId})
     
  return (
    <>
    <div class="d-inline-block text-nowrap">
      <button onClick={updateRow} class="btn btn-sm btn-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUpdateDepartment">
        <i style={{fontSize: 16}} class="bx bx-edit"></i>
      </button>
      <button onClick={deleteRow} class="btn btn-sm  btn-icon delete-record">
        <i style={{fontSize: 16, color:'red'}} class="bx bx-trash  "></i>
      </button>
      {/* <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
        <i style={{fontSize: 16}} class="bx bx-dots-vertical-rounded"></i>
      </button> */}
      <div class="dropdown-menu dropdown-menu-end m-0" style={{}}>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("View...",departmentId)}  class="dropdown-item">View</div>
        <div   style={{cursor:"pointer"}} onClick={()=>console.log("Suspending account...",departmentId)} class="dropdown-item">Suspend</div>
      </div>
    </div>
    {/* {
      alert ? swal(
        <div>
          <h1>Hello world!</h1>
          <p>
            This is now rendered with JSX!
          </p>
        </div>
      ) : null
    } */}
    </>
  )
}

 
export default  RowActions
 