import React, { useState}  from "react";
import { useSelector, useDispatch } from "react-redux"; 
import {  getServices, getBranches, sendReview} from "../../../../store/actions/queueManagement";
import { Link } from "react-router-dom";
import { Rating } from 'react-simple-star-rating'
import Alert from "../../../common/Alert"

 const CreateFeeback = (props) => {
  const dispatch = useDispatch()
   var {   currentOrganizationId }  =  useSelector((state) => state.organization);
  var {  popup }  =  useSelector((state) => state.appReducer);
    var { ticket } = useSelector((state) => state.queueManagement); 
  const [ isLoading, setIsloading ] = React.useState(false)
 
  React.useEffect(()=> { 
   
   if(currentOrganizationId){ 
      
      dispatch(getBranches(currentOrganizationId)) 
    dispatch(getServices(currentOrganizationId))
   }
    return () => {
    dispatch({type:'ON_UTILITY_SUBMITED'})
   }
}, [    currentOrganizationId ])

 
 
  const [rating, setRating] = useState(0)
  const [comments, setComments] = useState('')

  // Catch Rating value
  const handleRating = (rate) =>  setRating(rate)

   
  const handleSubmit = async ()  =>{
           setIsloading(true)
           const payload = { rating, comments, organizationId:ticket?.organizationId, ticketId:ticket?.id }
           await dispatch(sendReview(payload)).then((res) => { 
             dispatch({type:'ON_TICKET_CLEAR'}) 
             setRating(0)
             setComments('')
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Review submitted' }})
              setTimeout(() => {
                setIsloading(false)  
            dispatch({type:'HIDE_SWEET_ALERT'})
            }, 3000) 
            })
          .catch((e) => { 
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error submitting review' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false)   
          }, 4000);  
        }); 
  }

  const clearTicket = () => {
    dispatch({type:'ON_TICKET_CLEAR'}) 
  }

 

    return (
       
    <div className="card">  
      <div className="row mx-2 m-4 mb-0">
        <div className="col-md-8">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <h3>
                Shoot us a review {ticket?.ticketNumber}
              </h3>
            </div>
          </div>
      { popup.show ? 
        <div className="m-4">
            <div class="alert alert-success alert-dismissible" role="alert">
                <h6 class="alert-heading d-flex align-items-center fw-bold mb-1">Servece not available at this branch!!</h6>
                <p class="mb-0">{popup.message}</p>
                <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={clearTicket} aria-label="Close"></button>
            </div>
        </div>
      : null
      }
        <Alert/>
        </div> 
    </div> 
    

    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
      <div className="row mx-2 m-4">
      <div className="mb-4">
        {
           ticket?.number ? 
           <div className="m-4">
          <div class="alert alert-success alert-dismissible" role="alert">
              <h6 class="alert-heading d-flex align-items-center fw-bold mb-1">Your ticket number: {ticket?.number}</h6>
              <p class="mb-0">You will recieve a notification shortly</p>
 
                </div> 
               <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <Link  to={"/queue"} className="btn btn-primary " onClick={clearTicket}  
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>  View queue </span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </Link>
          </div>
      </div>
      : null
 
      }

      </div>
      {
         ticket?.number ? null :
         <>
       <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Rate us</label>
            <br/>
               <Rating onClick={handleRating} iconsCount={10}  />
           </div> 
 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Comments</label>
            <textarea required={true}  value={comments}  name="address"  onChange={(e)=>setComments(e.target.value)}  class="form-control" id="address" rows="5" placeholder="I fully recomment this, we need free wifi though"></textarea>
              
          </div> 
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <button className="btn btn-primary " onClick={handleSubmit} disabled={
                  isLoading || 
                  rating < 0.1 ||
                   comments === ""  
                   } 
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>{isLoading ? "Sending feedback, please wait" : "Save"}</span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
 
                </div> 
         </>
      } 
          
     </div> 
  
 
      </div>
    </div>
 

    </div>
   );
};



export default CreateFeeback
 