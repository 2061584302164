import {
    ON_QM_SUBMITED,

    ON_SCHEDULES__LOADED,

    ON_SERVICES_LOADED,

    ON_TICKETS_LOADED,

    ON_TICKET_KEY_UPDATED,
    ON_TICKET_QEUEUD, 

    
} from "../types/queueManagement/types"


var initialState = {
    tickets             :[], 
    services            :[], 
    reviews:[],
  
    
    ticket            :{ organizationId:'', ack: false, branchId:'', parentId:'', condition:0, serviceId: '', customerId: 814197464 }
}
  
export default (state = initialState, action) => {
const { type, payload } = action
 switch (type) {
     
    case ON_TICKETS_LOADED:   
        return { ...state, tickets: payload };

        case 'ON_REVIEWS_LOADED':   
        return { ...state, reviews: payload };

    case ON_SERVICES_LOADED:   
        return { ...state, services: payload };

    case ON_TICKET_KEY_UPDATED:  
        let ticketCopy = state.ticket
        ticketCopy[payload.key] = payload.val 
        return { ...state, ticket: ticketCopy };

        case ON_TICKET_QEUEUD:   
        return { ...state, ticket: payload };

        case "ON_TICKET_CLEAR":
            return {...state, ticket:{ organizationId:'', ack: false, branchId:'', parentId:'', condition:0, serviceId: '', customerId: 81417464 }}

 
     
 
     
  
    default: return state

    }
}
  