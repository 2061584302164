import React from "react";
import {  useDispatch } from "react-redux";
import  { deleteForm }  from "../../../../../store/actions/utilities/device";

const RowActions = ({deviceId}) => {
  const dispatch = useDispatch()
  const deleteRow = () => dispatch(deleteForm(deviceId)) 
  const updateRow = () => {
    console.log("deviceId", deviceId);
    dispatch({type:'ON_DEVICE_UPDATE', payload:deviceId})
  }
     
  return (
    <div class="d-inline-block text-nowrap">
      <button onClick={updateRow} class="btn btn-sm btn-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUpdateDevice">
        <i style={{fontSize: 16}} class="bx bx-edit"></i>
      </button>
      <button onClick={deleteRow} class="btn btn-sm  btn-icon delete-record">
        <i style={{fontSize: 16, color:'red'}} class="bx bx-trash  "></i>
      </button>
      {/* <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
        <i style={{fontSize: 16}} class="bx bx-dots-vertical-rounded"></i>
      </button> */}
      <div class="dropdown-menu dropdown-menu-end m-0" style={{}}>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("View...",deviceId)}  class="dropdown-item">View</div>
        <div   style={{cursor:"pointer"}} onClick={()=>console.log("Suspending account...",deviceId)} class="dropdown-item">Suspend</div>
      </div>
    </div>
  )
}

 
export default  RowActions
 