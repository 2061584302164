import React  from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import RowActions from './RowActions'
import AddDevice from './forms/AddDevice'
import UpdateDevice from './forms/UpdateDevice'
import moment from 'moment' 
import Constants from "../../../../../utils/Constants";

const columns = [ "Name", "Branch", "Properties", "created by", "Status", "Created", "Action"];
const DeviceList = () => {
  const dispatch = useDispatch()
  const format = "MMMM DD YYYY" 
  var {  users, apps, layouts }  =  useSelector((state) => state.organization);
  var {  branches, devices }  =  useSelector((state) => state.utilities);
  var appsData = []
  if(apps!==null){ appsData = (apps && apps.filter(element => element.product )) || [] }
   const [devicesArray, setDepartmentsArray] =  React.useState([])
   React.useEffect(()=>{ 
    setDepartmentsArray(devices) 
    return () => {
      dispatch({type:'ON_UTILITY_SUBMITED'})
     }
  }, [devices, branches, users, dispatch])

    return (
       
    <div className="card">  
      <div className="row mx-2 m-4">
        <div className="col-md-8">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                 {(devices && devices?.length) || 0 } Total devices
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
          
            <div className="dt-buttons">  
            <button className="dt-button add-new btn btn-primary" tabIndex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAddDepartment">
              <span><i className="bx bx-plus me-0 me-sm-2"></i><span className="d-none d-lg-inline-block">Add New Device</span></span>
            </button> 
          </div>
        </div>
      </div>
    </div> 
    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
 
          <MUIDataTable
          title={"Devices"}
          data={
            devicesArray !==null &&  devicesArray.map((device) => { 
              
                let created = `${moment(new Date(device.created * 1000).toJSON()).format(format) || 'No date'}`
                let user =  users && users?.find((u)=>  u.userId===device.createdBy )
                var fullname = "System"
                if(user){
                  const { firstname,lastname }  = user
                  fullname = ( firstname   +  ' ' + lastname)
                }
                let branchName = '', branch =  branches && branches?.find((b)=>  b.id===device?.branchId )
                 if(branch){
                  branchName = branch.name
                }

                let solution =  (appsData && appsData?.find((a)=> a.id===device?.solutionId )) || 'None'
                var deviceLayout = ''
                if(device?.layoutId){
                  deviceLayout = (layouts && layouts?.find((l)=> l.id===device.layoutId )) || 'None'
 
                } 
                let properties = 
                <div class=" ">
                         <span class="badge badge-sm bg-label-primary m-1">{solution.name || 'None'}</span>
                         
                        <>{Boolean(device?.queueManagement )? <span span class="badge bg-label-secondary mb-2">Queue snyced</span> : null}</>
                       
                      {
                        device.solutionId === 'h5tCHPaaoGvc7b84BE5u' ? <>
                          <span class="badge bg-label-dark m-1">{deviceLayout.name || 'None'}</span></> :null
                      }
                         <div class="d-flex align-items-center lh-1 me-3 m-1 ">
                        <span class="badge badge-dot bg-danger me-1"></span> Offline
                      </div>
                      
                 </div> 
                  let name = 
                  <div class="d-flex flex-column">
                    <div class="text-body text-truncate">
                      <span class="fw-semibold">{device.name}</span>
                    </div>
                    <small class="text-muted">{device.description}</small>
                   </div> 
 
 
                let  status = Constants.STATUS.find((s) => s.id===device.deviceStatus)
                let active = status ?  <span class={`badge bg-label-${status.type}`}>{status.name}</span>  :<span class={`badge bg-label-secondary`}>INACTIVE</span>
              
              return  [name, branchName, properties, fullname, active,created,<RowActions deviceId={device.id}/>]                
           }) 

          }
          columns={columns} 
          fixedHeader={true} 
          /> 
      </div>
    </div>

        <UpdateDevice/>
        <AddDevice/>

    </div>
   );
};



export default DeviceList
 