import React from "react";
 import CreateFeeback from './queue/CreateFeeback'

const Feedback = () => {  
   return ( 
      <CreateFeeback />
      
   );
};

export default Feedback;
