import React from "react";
import { useSelector, useDispatch } from "react-redux";
import  { createForm }  from "../../../../../../store/actions/utilities/department";
import Constants from "../../../../../../utils/Constants";
import Alert from "../../../../../common/Alert"

const AddDepartment = () => {
 
    const dispatch = useDispatch()
    var { branches, department }  =  useSelector((state) => state.utilities);
    const [ isloading, setIsloading ] =  React.useState(false)
    React.useEffect(() => {
       return () => {
           dispatch({type:'ON_UTILITY_SUBMITED'})
        }
    },[ dispatch])  
 
     const createDepartment = () => {
      setIsloading(true) 
      dispatch(createForm(department)).then((res) => { 
         dispatch({type:'ON_UTILITY_SUBMITED'})
         dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Department successfully created' }})
          setTimeout(() => {
           document.getElementById("closeBranchAdd").click();
          dispatch({type:'HIDE_SWEET_ALERT'})
          setIsloading(false)  
          }, 2000); 
          })
        .catch((e) => { 
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error creating department' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false)   
           }, 4000); 
            
        }); 
      }

    const onChange = (key, val) =>  dispatch({type:'ON_DEPARTMENT_KEY_UPDATED', payload:{ key, val }})
     const validateDepartment = (e) => {
       e.preventDefault()
       const isEmpty = Object.values(department).every(value => !!value && value?.trim() !== '');
       if(!isEmpty){
       dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'All fields are required' }})
        setTimeout(() => {
          dispatch({type:'HIDE_SWEET_ALERT'}) 
        }, 4000);
     }
     else {
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'secondary', text:'Creating department' }})
          createDepartment()
      }
    }
     
      
   
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAddDepartment" aria-labelledby="offcanvasAddUserLabel">
    <div className="offcanvas-header">
      <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Add Department</h5>
      <button type="button" id="closeBranchAdd" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body mx-0 flex-grow-0"> 
      <Alert/>
       <form className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewBranchForm" onSubmit={(e)=>validateDepartment(e)} noValidate="novalidate">
        <div className="mb-3 fv-plugins-icon-container">
          <label className="form-label" htmlFor="add-user-fullname">Name</label>
          <input value={department.name || ''} required={true} type="text" className="form-control" id="branch_name" onChange={(e)=>onChange('name', e.target.value)} placeholder="Production" name="userFullname" aria-label="John Doe"/>
        <div className="fv-plugins-message-container invalid-feedback"></div>
        </div> 
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Branch</label>
          <select id="user-plan" onChange={(e)=>onChange('branchId', e.target.value)} className="form-select">
            <option disabled={true} selected value="" >Select branch</option>
          {
              branches.map((b)=>{
                 return  <option  value={b.id} >{b.name}</option>
              })
            } 
          </select> 
        </div> 
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Status</label>
          <select id="user-plan"  selected={department.branchStatus} onChange={(e)=>onChange('departmentStatus', e.target.value)} className="form-select">
          {
              Constants.STATUS.map((s )=>{ 
                return  <option  value={s.id} >{s.name}</option>
              })
            } 
          </select> 
        </div>
        <button type="submit"
            style={{  alignItems:"center", justifyContent:"space-between"}} 
            disabled={
                department.name === '' ||  
                 isloading
            } 
            className="btn btn-primary me-sm-3 me-1 data-submit">Submit &nbsp;
            {isloading && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
        </button>
        <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
      <div></div><input type="hidden"/>
      </form>
    </div>
  </div>
  )
}

export default AddDepartment