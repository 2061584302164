import React  from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import RowActions from './RowActions'
import AddDepartment from './forms/AddDepartment'
import UpdateDepartment from './forms/UpdateDepartment'
import moment from 'moment' 
import Constants from "../../../../../utils/Constants";

const columns = [ "Name", "Branch", "created by", "Status", "Created", "Action"];
const DepartmentList = () => {
  const dispatch = useDispatch()
  const format = "MMMM DD YYYY" 
  var {  users }  =  useSelector((state) => state.organization);
  var {  branches, departments }  =  useSelector((state) => state.utilities);
   const [departmentsArray, setDepartmentsArray] =  React.useState([])
   React.useEffect(()=>{ 
    setDepartmentsArray(departments) 
    return () => {
      dispatch({type:'ON_UTILITY_SUBMITED'})
     }
  }, [departments, branches, users, dispatch])

    return (
       
    <div className="card">  
      <div className="row mx-2 m-4">
        <div className="col-md-8">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                 {(departments && departments?.length) || 0 } Total departments
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
          
            <div className="dt-buttons">  
            <button className="dt-button add-new btn btn-primary" tabIndex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAddDepartment">
              <span><i className="bx bx-plus me-0 me-sm-2"></i><span className="d-none d-lg-inline-block">Add New Department</span></span>
            </button> 
          </div>
        </div>
      </div>
    </div> 
    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
 
          <MUIDataTable
          title={"Departments"}
          data={
            departmentsArray !==null &&  departmentsArray.map((department) => { 
              
                let created = `${moment(new Date(department.created * 1000).toJSON()).format(format) || 'No date'}`
                let user =  users && users?.find((u)=>  u.userId===department.createdBy )
                var fullname = "System"
                if(user){
                  const { firstname,lastname }  = user
                  fullname = ( firstname   +  ' ' + lastname)
                }
                let branchName = '', branch =  branches && branches?.find((b)=>  b.id===department.branchId )
                 if(branch){
                  branchName = branch.name
                }
 
                let  status = Constants.STATUS.find((s) => s.id===department.departmentStatus)
                let active = status ?  <span class={`badge bg-label-${status.type}`}>{status.name}</span>  :<span class={`badge bg-label-secondary`}>INACTIVE</span>
              
              return  [department.name, branchName, fullname, active,created,<RowActions departmentId={department.id}/>]                
           }) 

          }
          columns={columns} 
          fixedHeader={true} 
          /> 
      </div>
    </div>

        <UpdateDepartment/>
        <AddDepartment/>

    </div>
   );
};



export default DepartmentList
 