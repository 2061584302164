import React from "react";
import { Link } from 'react-router-dom'

const ComingSoon = () => {
   
    return  (
    <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="col-lg-8 mb-4 order-0">
                  <div class="card">
                    <div class="d-flex align-items-end row">
                      <div class="col-sm-7">
                        <div class="card-body">
                          <h5 class="card-title text-primary">Coming soon! </h5>
                          <p class="mb-4">
                            You have   more applications on your way. Click below for early access.
                          </p>

                          <Link to={'/market-place'} class="btn btn-sm btn-outline-primary">Join mailing list</Link>
                        </div>
                      </div>
                      <div class="col-sm-5 text-center text-sm-left">
                        <div class="card-body pb-0 px-0 px-md-4">
                          <img src={require("../assets/img/illustrations/man-with-laptop-light.png")}  height="140" alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
            </div>
          
            <div class="content-backdrop fade"></div>
          </div>
          </div>
          )
        
    

 
}

export default ComingSoon
