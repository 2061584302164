import React from "react";
import { useSelector, useDispatch } from "react-redux";
import  { updateForm }  from "../../../../../../store/actions/utilities/department";
import Alert from "../../../../../common/Alert"
import Constants from "../../../../../../utils/Constants";
 
 
const UpdateDepartment = () => {

    const dispatch = useDispatch()
    var { branches, department }  =  useSelector((state) => state.utilities);
    const [ isloading, setIsloading ] =  React.useState(false)
    const updateDepartment = () => { 
     dispatch(updateForm(department)).then((res) => { 
        dispatch({type:'ON_UTILITY_SUBMITED'})
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Department successfully updated' }})
         setTimeout(() => {
          document.getElementById("closeBranchUpdate").click();
         dispatch({type:'HIDE_SWEET_ALERT'})
         setIsloading(false) 
         }, 2000); 
         })
       .catch((e) => { 
         dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error updating department' }})
         setTimeout(() => {
           dispatch({type:'HIDE_SWEET_ALERT'})
           setIsloading(false)   
          }, 4000); 
       }); 
     }
    
    const onChange = (key, val) => dispatch({type:'ON_DEPARTMENT_KEY_UPDATED', payload:{ key, val }})
    
  
    const validateDepartment = (e) => {
      e.preventDefault()
      let payload = {name:department.name, branchId:department.branchId, departmentStatus:department.departmentStatus}
      const isEmpty = Object.values(payload).every(value => !!value && value?.trim() !== '');
         if(!isEmpty){
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'All fields are required' }})
         setTimeout(() => {
           dispatch({type:'HIDE_SWEET_ALERT'}) 
         }, 4000);
      }
      else {
           dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'secondary', text:'Updating department' }})
           updateDepartment()
       }
 
   }
   
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasUpdateDepartment" aria-labelledby="offcanvasAddUserLabel"  >
    <div className="offcanvas-header">
      <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Department</h5>
      <button type="button" id="closeBranchUpdate" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body mx-0 flex-grow-0">
     <Alert/>
     <form className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewBranchForm" onSubmit={(e)=>validateDepartment(e)} noValidate="novalidate">
        <div className="mb-3 fv-plugins-icon-container">
          <label className="form-label" htmlFor="add-user-fullname">Name</label>
          <input value={department.name} required={true} type="text" className="form-control" id="branch_name" onChange={(e)=>onChange('name', e.target.value)} placeholder="Production" name="userFullname" aria-label="John Doe"/>
        <div className="fv-plugins-message-container invalid-feedback"></div></div> 
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Branch</label>
          <select id="user-plan"  selected={department.branchId} onChange={(e)=>onChange('branchId', e.target.value)} className="form-select">
          
          {
              branches.map((b)=>{
                 return  <option  value={b.id} >{b.name}</option>
              })
            } 
          </select> 
        </div> 
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Status</label>
          <select id="user-plan"  selected={department.branchStatus} onChange={(e)=>onChange('departmentStatus', e.target.value)} className="form-select">
          {
              Constants.STATUS.map((s )=>{ 
                return  <option  value={s.id} >{s.name}</option>
              })
            } 
          </select> 
        </div>
        <button type="submit"
            style={{  alignItems:"center", justifyContent:"space-between"}} 
            disabled={
                department.name === '' ||  
                 isloading
            } 
            className="btn btn-primary me-sm-3 me-1 data-submit">Submit &nbsp;
            {isloading && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
        </button>
        <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
      <div></div><input type="hidden"/>
      </form>
    </div>
  </div>
  )
}

export default UpdateDepartment