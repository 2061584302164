import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


const RowActions = (userId) => {
  var { user : currentUser, userData } = useSelector((state) => state.auth)

  return (
    <div class="d-inline-block text-nowrap">
      <Link onClick={()=>console.log("Editing...",userId)} to={`/profile/${userId?.userId}`} class="btn btn-sm btn-icon" >
        <i style={{fontSize: 16}} class="bx bx-edit"></i>
      </Link>
      {/* <button onClick={()=>console.log("Deleting...",userId)} class="btn btn-sm  btn-icon delete-record">
        <i style={{fontSize: 16, color:'red'}} class="bx bx-trash  "></i>
      </button> */}
      {/* <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
        <i style={{fontSize: 16}} class="bx bx-dots-vertical-rounded"></i>
      </button> */}
      <div class="dropdown-menu dropdown-menu-end m-0" style={{}}>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("View...",userId)}  class="dropdown-item">View</div>
        <div   style={{cursor:"pointer"}} onClick={()=>console.log("Suspending account...",userId)} class="dropdown-item">Suspend</div>
      </div>
    </div>
  )
}

export default RowActions