import React from "react";
import ReactDOM from "react-dom";
import store from "./store/index";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";


import 'boxicons';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/vendor/css/rtl/core.css";
// import "./assets/vendor/css/rtl/core-dark.css";
 import "./assets/vendor/css/rtl/theme-default.css";
import "./assets/css/demo.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/libs/typeahead-js/typeahead.css";
import "./assets/vendor/libs/apex-charts/apex-charts.css"; 

import 'bootstrap/dist/js/bootstrap.js'; 
import "./assets/css/loop.css";
 
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
); 

serviceWorker.register();
