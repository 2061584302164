import { doc, addDoc,  onSnapshot, updateDoc, collection, deleteDoc, runTransaction} from "firebase/firestore";
import { db } from "../../../utils/firebase";
const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()
 
export const createForm = (payload) => async (dispatch, getState) => { 
      dispatch({ type: 'ON_DATA_SUBMITING' });
      
      var {  organization, auth } = getState()  
      const organizationObject = organization.organization
      payload = { ...payload, createdBy:auth.user.uid, organizationId:organizationObject.id, created}
      try{
        await addDoc(collection(db, "utilities/data/branches"),  { ...payload } )
      .then((res) =>{  return  res })
      .catch((error) => { 
        return  error
      }); 
    } catch (err) { 
       return  err
    }
  };
  
export const updateForm = (payload) => async (dispatch) => { 
  const taskDocRef = doc(db,  "utilities/data/branches", payload?.id)
  console.log("payload", payload);
     try {
       await updateDoc(taskDocRef,  {...payload, updated})
       .then((res) =>{ 
         console.log("====payload====", res);
         return  res
        })
        .catch((error) => { 
          console.log("====payload====", error);
          return  error
        });
    } catch (err) { return err }
};
 
         
export const deleteForm = (id) => async () => {
   if(id){
     await deleteDoc(doc(db, "utilities/data/branches", id))
    .then(()=>{ }).catch( error => { } )
  };

  }

 