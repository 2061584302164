import React from "react"; 
import { useSelector, useDispatch } from "react-redux";
import { 
  getTickets, getServices, 
  getReviews } from "../store/actions/queueManagement";
  import { Link } from "react-router-dom";
  import BookingsList from "../components/apps/booking/Tickets"
   import CreateAppointment from "../components/apps/booking/queue/CreateAppintment";



 
const Bookings = (props) => {
   
  const dispatch = useDispatch()
  var { organization, customerId } = useSelector((state) => state.organization);
  var { tickets, services, } = useSelector((state) => state.queueManagement);
  var { match } = props
  var components = [  
        {name: "bookings",     page:BookingsList, title:"Bookings"},
      {name: "add",        page:CreateAppointment, title:"Bookings"},
    ]
 
  React.useEffect(() => { 
    if(organization?.id?.length > 0){
      if(tickets?.length ===0)  dispatch(getTickets(organization?.id))
      if(tickets?.length ===0)  dispatch(getReviews(organization?.id))
      
       
      if(services?.length ===0)  dispatch(getServices(organization?.id))
  
    }
    },[ organization,  dispatch])

  
  var component = components.find(item => item?.name === match?.params?.pageId || '');
  let DynamicComponent
  document.title = "Queue " + component?.title || ''
  if(component!=null ) {
    DynamicComponent =  component.page
  }
  else {
    document.title = "Bookings "
      DynamicComponent = BookingsList
  }
    
  return (<>
    <nav class="navbar navbar-expand-lg bg-white mb-4">
      <div class="container-fluid">
        <Link class="navbar-brand"to="/bookings">Appointments</Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-ex-6">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar-ex-6">  
          <ul class="navbar-nav m-2">
          <div class=" ">
            <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
              <div class="dt-buttons"><Link to="/bookings/add" class="  add-new btn btn-primary" tabindex="0" type="button"  ><span><i class="bx bx-plus me-0 me-sm-2"></i><span class=""> Add Bookings</span></span></Link></div></div></div>
 
          </ul>
        </div>
      </div>
    </nav>
  <DynamicComponent/> 
  </> )

}
 
export default Bookings
