export default [
    {
      allDay: false,
      end: new Date('2022-10-05T22:13:46.504Z'),
      start: new Date('2022-10-05T21:13:46.503Z'),
      title: 'Cooporate meeting  with EXCO'
    },
    {
      allDay: false,
      end: new Date('2022-10-05T08:00:00.000Z'),
      start: new Date('2022-10-05T03:00:00.000Z'),
      title: 'Accounts Payment Follow ups'
    },
    {
      allDay: false,
      end: new Date('2022-10-05T21:00:00.000Z'),
      start: new Date('2022-10-05T13:00:00.000Z'),
      title: 'General enquiries'
    },
    {
      allDay: true,
      end: new Date('2022-10-05T22:00:00.000Z'),
      start: new Date('2022-10-04T22:00:00.000Z'),
      title: 'Sim card fraud meeting'
    },
    {
      allDay: true,
      end: new Date('2022-10-06T22:00:00.000Z'),
      start: new Date('2022-10-04T22:00:00.000Z'),
      title: 'Clarity of MTC shares'
    },
    {
      allDay: false,
      end: new Date('2022-10-08T02:13:46.511Z'),
      start: new Date('2022-10-05T02:13:46.511Z'),
      title: 'Contract issues'
    }
  ]