import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import  { createForm }  from "../../../../../../store/actions/utilities/branch";
import Constants from "../../../../../../utils/Constants";
import Alert from "../../../../../common/Alert"

const AddBranch = () => {
 
    const dispatch = useDispatch()
    var { branch }  =  useSelector((state) => state.utilities);
    const [ isloading, setIsloading ] =  React.useState(false)
    React.useEffect(() => {
       return () => {
           dispatch({type:'ON_UTILITY_SUBMITED'})
        }
    },[ dispatch])  
 
     const createBranch = () => {
      setIsloading(true)
      dispatch(createForm(branch)).then((res) => { 
         dispatch({type:'ON_UTILITY_SUBMITED'})
         dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Branch successfully created' }})
          setTimeout(() => {
           document.getElementById("closeBranchAdd").click();
          dispatch({type:'HIDE_SWEET_ALERT'})
          setIsloading(false) 
          }, 3000); 
          })
        .catch((e) => { 
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error creating branch' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false)  
           }, 4000); 
            
        }); 
      }

    const onChange = (key, val) =>  dispatch({type:'ON_BRANCH_KEY_UPDATE', payload:{ key, val }})
     const validateBranch = (e) => {
       e.preventDefault()
       const isEmpty = Object.values(branch).every(value => !!value && value?.trim() !== '');
       if(!isEmpty){
       dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'All fields are required' }})
        setTimeout(() => {
          dispatch({type:'HIDE_SWEET_ALERT'}) 
        }, 4000);
     }
     else {
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'secondary', text:'Creating branch' }})
          createBranch()
      }
    }
     
      
   
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAddBranch" aria-labelledby="offcanvasAddUserLabel">
    <div className="offcanvas-header">
      <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Add Branch</h5>
      <button type="button" id="closeBranchAdd" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body mx-0 flex-grow-0"> 
      <Alert/>
       <form className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewBranchForm" onSubmit={(e)=>validateBranch(e)} noValidate="novalidate">
        <div className="mb-3 fv-plugins-icon-container">
          <label className="form-label" htmlFor="add-user-fullname">Name</label>
          <input value={branch.name || ''} required={true} type="text" className="form-control" id="branch_name" onChange={(e)=>onChange('name', e.target.value)} placeholder="SSC Khomasdal" name="userFullname" aria-label="John Doe"/>
        <div className="fv-plugins-message-container invalid-feedback"></div></div> 
        <div className="mb-3">
          <label className="form-label" htmlFor="user-role">Country</label>
          <CountryDropdown
                value={branch.country || ''}
                onChange={(e)=>onChange('country', e)}
                className=" form-select form-select-lg  form-control"
            />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="user-role">State / Region / Province </label>
          <RegionDropdown
                disableWhenEmpty={true}
                country={branch.country || ""}
                value={branch.region || ''}
                onChange={(e)=>onChange('region', e)}
                className="form-select form-select-lg  form-control"
            />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="address">Address</label>
          <textarea required={true} value={branch.address || ''} name="address"  onChange={(e)=>onChange('address', e.target.value)}  class="form-control" id="address" rows="2" placeholder="1456, Mall Road"></textarea>
         </div>
        <div className="mb-4">
          <label className="form-label" htmlFor="user-plan">Status</label>
          <select id="user-plan"  selected={branch.branchStatus} onChange={(e)=>onChange('branchStatus', e.target.value)} className="form-select">
            <option  value={''} >{'Select branch'}</option>
            {
              Constants.STATUS.map((s, i)=>{
                i++
                return  <option  value={s.id} >{s.name}</option>
              })
            } 
          </select> 
        </div>
        <button type="submit"
            style={{  alignItems:"center", justifyContent:"space-between"}} 
            disabled={
                branch.name === '' ||  
                branch.county === '' ||
                branch.region === '' ||
                branch.address === '' ||
                branch.branchStatus === '' ||
                isloading
            } 
            className="btn btn-primary me-sm-3 me-1 data-submit">Submit &nbsp;
            {isloading && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
        </button>
        <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
      <div></div><input type="hidden"/>
      </form>
    </div>
  </div>
  )
}

export default AddBranch