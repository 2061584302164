import React from "react";
import { useSelector } from "react-redux";
import Stats from './users/Stats'
import UserList from './users/UserList'


const EditUser = ( props ) => { 
    var  {  users }  =  useSelector((state) => state.organization); 
    if(!users){
        return <div data-i18n=" ">Loading users...</div>
    }
    
   return (
       
      <> 
           <Stats/>
           <UserList/>
     </>
   );
};

export default EditUser;
