import React from "react";
import Stats from './storage/Stats'
import StorageList from './storage/StorageList'

const Storage = () => { 
     return (
      <> 
         <Stats/>
         <StorageList />
     </>
   );
};

export default Storage;
