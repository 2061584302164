import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import  { updateProfilePictue }  from "../store/actions/utilities/updateProfilePictue";
import  { updateAccount }  from "../store/actions/user";
 
import Select from 'react-select';
import Constants from "../utils/Constants";
import Alert from "../components/common/Alert"

  

const AddAccount = () => {
    
    var  { apps, users, branches }  =  useSelector((state) => state.organization);
    var  { branches, departments }  =  useSelector((state) => state.utilities);
    let { pageId } = useParams();
    if( pageId!=null) var userId = pageId.split('/').pop()
   
    var user = users && users.find((user)=> { return user.userId === userId })
    const dispatch = useDispatch();
    const [ selectedPermissions, setSelectedPermissions ] =  React.useState([])
    const [ isLoading, setIsloading] = React.useState(false)
    const [ rowState, setRowState ] = React.useState({...user})
    const [ preview, setPreview ] = React.useState('')

    const [file, setFile] = React.useState(""); 
    const appsData = [];
    apps && apps.forEach((a)=>{
     let app = {value:a.id, label:a.name}
     appsData.push(app)
    })

    React.useEffect(() => { 
                var userPermissions = [] 
                if(user){
                    let x = apps?.forEach((app)=>{
                     let permission = user?.permissions?.find((p)=>p===app.id) 
                     if(permission===app.id){ 
                       let perm = {value:app?.id, label:app?.name}
                      userPermissions.push({value:app?.id, label:app?.name})  
                     }
                   })
                   if(userPermissions.length>0) { 
                     setSelectedPermissions(userPermissions)
                }  
            }
      },[ branches, departments, users,  apps
      ]) 
    
    const editRow = ( key, value) => { 
         setRowState( prevState => ({
            ...prevState,
            [key]: value
            })
        ); 
    }

    const validateUser = () => {
        let perm = selectedPermissions?.map((p)=> {return p.value } ) || []
        rowState['permissions'] = perm
        var  { firstname, lastname, email,  branchId, permissions, departmentId }  = rowState
       
         if(firstname === ""){
             dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a firstname' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        }
        else if(lastname === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a lastname' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        }
        else if(email === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide an email' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        } 
        else if(branchId === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please select a branch' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(departmentId === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please select a department' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(permissions.length === 0){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please add at least one user permission' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }

        else if (file!=='') { 
              setIsloading(true)
            dispatch(updateProfilePictue(file, rowState)).then((res) => { 
                dispatch({type:'ON_UTILITY_SUBMITED'})
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'image uploaded successfuly' }})
                setTimeout(() => {
                 dispatch({type:'HIDE_SWEET_ALERT'})
                setIsloading(false) 
                }, 3000); 
                })
              .catch((e) => { 
                 dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error uploading image' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                  setIsloading(false)  
                 }, 4000); 
                  
              }); 
        
            
        } 
        else{
             setIsloading(true)
            dispatch(updateAccount(rowState)).then((res) => { 
                dispatch({type:'ON_UTILITY_SUBMITED'})
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Account updated successfuly' }})
                setTimeout(() => {
                 dispatch({type:'HIDE_SWEET_ALERT'})
                setIsloading(false) 
                }, 3000); 
                })
              .catch((e) => { 
                 dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error updating account' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                  setIsloading(false)  
                 }, 4000); 
                  
              }); 
       
        }
      }

      const saveUpload = (e) => {
        e.preventDefault()
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'info', text:'uploading new image' }})
        setIsloading(true) 
         dispatch(updateProfilePictue(file, rowState)).then((res) => { 
            dispatch({type:'ON_UTILITY_SUBMITED'})
           dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'image uploaded successfuly' }})
            setTimeout(() => {
             dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false) 
            }, 3000); 
            })
          .catch((e) => { 
            console.log(e);
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error uploading image' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
              setIsloading(false)  
             }, 4000); 
              
          }); 
      }

    const handleFileuploadChange = (e) => {
        e.preventDefault()
        setFile(e.target.files[0]); 
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const toggleUserStatus = e => {
         setRowState( prevState => ({
            ...prevState,
            active: !e.target.value
            })
        );
    }

 if(!user){
    return <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
        <div className="col-md-12">
        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Loading user account...</span></h4>
        </div>
        </div>
    </div>
 }
    
return (
    
    <div className="container-xxl flex-grow-1 container-p-y">

        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Account /</span> Edit</h4>

    <div className="row">
    <div className="col-md-12">
    
        <ul className="nav nav-pills flex-column flex-md-row mb-3">
        <li className="nav-item">
            <a className="nav-link active" href="/"><i className="bx bx-user me-1"></i> Details</a>
        </li>
        {/* <li   className="nav-item" >
            <Link to={`/passwords/${user.userId}`} className="nav-link" 
            ><i className="bx bx-lock me-1"></i> Privacy</Link  >
        </li>  */}
        </ul>
        <div className="card mb-4">
        <h5 className="card-header">Update user privacy </h5>
        <div className="card-body">
        <Alert/>
            <div className="d-flex align-items-start align-items-sm-center gap-4">
                {
                    <img
                    src={preview.length === 0 ? rowState?.profileUrl : preview} 
                    alt={""}
                    className="d-block rounded"
                    style={{width: 100, height:100, backgroundColor:"#f5f5f9", display:"flex"}}
                    id="uploadedAvatar"
                /> 
                }
            
            <div className="button-wrapper">
                <label for="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
                <span className="d-none d-sm-block">Upload new photo</span>
                <i className="bx bx-upload d-block d-sm-none"></i>
                <input
                    type="file"
                    id="upload"
                    className="account-file-input"
                    hidden
                    name="fileurl"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e)=>{ handleFileuploadChange(e) }}
                />
                </label>
                <button type="button" onClick={(e)=>saveUpload(e)} className="btn btn-outline-secondary account-image-reset mb-4">
                <i className="bx bx-reset d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Save</span>
                </button>

                <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 2MB</p>
                
            </div>
            </div>
        </div>
        <br/>
            <hr className="my-0" />
        <div className="card-body">
            <form id="formAccountSettings">
            <div className="row">
                <div className="mb-3 col-md-6">
                <label for="firstName" className="form-label">First Name</label>
                <input
                    className="form-control"
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={(e)=>editRow("firstname", e.target.value)}
                    value={ rowState?.firstname}
                    autoFocus
                />
                </div>
                <div className="mb-3 col-md-6">
                <label for="lastName" className="form-label">Last Name</label>
                <input 
                    className="form-control" 
                    type="text" 
                    name="lastName" 
                    id="lastName" 
                    onChange={(e)=>editRow("lastname", e.target.value)}
                    value={ rowState?.lastname }
                />
                </div>
                <div className="mb-3 col-md-6">
                <label for="email" className="form-label">E-mail</label>
                <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    onChange={(e)=>editRow("email", e.target.value)}
                    value={ rowState?.email }
                    placeholder="john.doe@example.com"
                />
                </div>
                <div className="mb-3 col-md-6">
                <label className="form-label" for="password">Password</label>
                <input
                    className="form-control"
                    type="password"
                    id="password"
                    name="password"
                    disabled={true}
                        // Display empty if ther is a long hash and update on change
                    // value={ rowState?.password.length > 50 ? '' : rowState?.password}
                    />
                <Link to={`/passwords/${user.userId}`}><p  >update password</p></Link>
                </div>

                <div className="mb-3 col-md-6">
                <label className="form-label" for="branch">Branch</label>
                <select id="branch" value={rowState?.branchId} onChange={(e)=>editRow("branchId", e.target.value)} className="select2 form-select">
                    <option value="">Select</option>
                    {branches?.map( ({ id, name }) => <option className="" key={id} value={id}>{name}</option> )} 
                </select>
                </div>
                <div className="mb-3 col-md-6">
                <label for="role" className="form-label">Role</label>
                <select id="role" value={rowState?.role} onChange={(e)=>editRow("role", e.target.value)} className="select2 form-select">
                    <option value="" disabled={true}>Select role</option>
                    <option value="1">Admin</option>
                    <option value="2">Branch Manager</option>
                    <option value="3">Supervisor</option>
                    <option value="4">Consultant/ Officer</option>
                </select>
                </div>
                <div className="mb-3 col-md-6">
                <label className="form-label" for="branch">Department</label>
                <select id="branch" value={rowState?.departmentId} onChange={(e)=>editRow("departmentId", e.target.value)} className="select2 form-select">
                    <option value="">Select</option>
                    {departments?.map( ({ id, name }) => <option className="" key={id} value={id}>{name}</option> )}
                        
                </select>
                </div>
                <div className="mb-3 col-md-6">
                <label for="role" className="form-label">Account Status</label>
                <select id="role" slected={rowState?.userStatus} onChange={(e)=>editRow("userStatus", e.target.value)} className="select2 form-select">
                    {
                        Constants.STATUS.map((s )=>{  
                            return  <option  value={s.id} >{s.name}</option>
                        })
                    } 
                </select>
                </div>
                
                <div className="mb-6 col-md-12">
                
                <div className="mb-4">
                <label for="permissions" className="form-label">Apps Permissions</label>
                <Select
                // defaultValue={selectedPermissions}
                value={selectedPermissions}
                onChange={(e)=>setSelectedPermissions(e)}
                options={appsData}
                isMulti
                name="services"
                className="basic-multi-select"
                classNamePrefix="select"
                />
            </div>  
                </div>

                
            </div>
            <div className="mt-2" >
                <div disabled={
                    isLoading
                } onClick={validateUser} style={{}} className="btn btn-primary me-2">Save changes</div>
                <Link to="/utilities/users"  className="btn btn-outline-secondary">Cancel</Link>
            </div>
            </form>
        </div>
        </div>


        <div className="card" style={{pointerEvents:"none"}}>
        <h5 className="card-header">Delete Account</h5>
        <div className="card-body">
            <div className="mb-3 col-12 mb-0">
            <div className="alert alert-warning">
                <h6 className="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
                <p className="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
            </div>
            </div>
            <div id="formAccountDeactivation" >
            <div className="form-check mb-3">
                <input
                className="form-check-input"
                type="checkbox"
                name="accountActivation"
                value={user.active}
                // disable={!user.admin}
                // onChange={toggleUserStatus}
                id="accountActivation"
                />
                <label className="form-check-label" for="accountActivation">I confirm my account deactivation</label>
            </div>
            {
            rowState.active ?
            <button type="submit" onClick={validateUser}  className="btn btn-danger deactivate-account">Deactivate Account</button>
            :
            <button type="submit" onClick={validateUser}   className="btn btn-seccess deactivate-account">Activate Account</button>
        }
            </div>
        </div>
        </div>

        
    </div>
    </div>
    
    </div>
)};

export default AddAccount;
