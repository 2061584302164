import React from "react";
 import JoinQueue from './queue/JoinQueue'

const Queue = () => {  
   return ( 
      <JoinQueue />
      
   );
};

export default Queue;
