import {
  
  ON_ORGANIZATIONS_LOADED,
  ON_CURRENT_ORGANIZATIONID,

  ON_ORGANIZATION_START,
  ON_ORGANIZATION_SUCCESS,
  ON_BRANCHES_LOADED,
  ON_SERVICES_LOADED,
   ON_USERS_START,
  ON_USERS_SUCCESS,
  ON_USERS_FAIL,
  ON_USERS_UPDATE,
  ON_USERS_DELETE,

  ON_BRANCH_DELETE,

  SET_MESSAGE
} from "../actions/types";

 
var initialState = {
    organization:null,
    organizations:[],
    currentOrganizationId:null,
    branches:[], 
    services:[],
    users:[],
    blocks:[],
    storage:[],
    forms:[],

    layouts:[], 
    showMainMenu:false,

     isLoading:false,
    userCrud: {
      active: false,
      id:null,
      admin: false,
      created: "",
      email: "",
      fisrtname: "",
      lastname: "",
      organizationId: "",
      branchId:"",
      password: "",
      profileUrl: "",
      filename:"",
      role: null,
      permissions: [],
      userId: "",
      uuid: "",
    },
    organization     : {
      active: false,
      country: "",
      created: "",
      logoUrl: "",
      id:null,
      name: "",
      logoname:"",
      ownerEmail: "",
      ownerId: "",
      setupDone: false,
      shortName: "",
      uuid: "",
    },
    customerId: 81417464 
   }


export default function (state = initialState, action) {
  const { type, payload } = action;
   switch (type) {
    case ON_ORGANIZATION_SUCCESS:
        Object.keys(payload).forEach( async (key) => state[key] = payload[key] )
        return {
        ...state,
      };

      case "ON_TICKET_CLEAR":
        return { ...state, organization:null, currentOrganizationId:null, branches:[],  services:[],}

    case ON_ORGANIZATIONS_LOADED: 
      return {
        ...state,
        organizations: payload ,
      };

    case ON_CURRENT_ORGANIZATIONID: 
      return {
        ...state,
        currentOrganizationId: payload ,
      };
       
    case ON_BRANCHES_LOADED:
        return {
          ...state,
          branches: payload ,
        };
    case ON_SERVICES_LOADED:
      return {
        ...state,
        services: payload ,
       };

        // USERS
    case ON_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
       };
    case ON_USERS_UPDATE:
      return {
        ...state,
        users: state.users.find( user => user.id === action.payload )
      };

      case ON_USERS_DELETE:
        return {
          ...state,
          users: state.users.find( user => {return user.id !== action.payload} )
        };

      case ON_USERS_FAIL:
      return {
        ...state
      };
 
 
        case 'TOGGLE_MAIN_MENU':
           return {
          ...state,
          showMainMenu: !state.showMainMenu
        };

    default:
      return state;
  }
  
}
