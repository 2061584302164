import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
 import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {  storage } from "../utils/firebase"; 
import  { createFirestoreUser }  from "../store/actions/user";
import authService from "../store/services/authService";
import { Link } from "react-router-dom";

import fileExtension from 'file-extension'
import Select from 'react-select';
import Constants from "../utils/Constants";
import Alert from "../components/common/Alert"

const {  v1: uuidv1, } = require('uuid');
 
const DefaultUser = {
    active: false,
    id:null,
    departmentId:"",
    admin: false,
    created: "",
    email: "",
    firstname: "",
    lastname: "",
    organizationId: "",
    branchId:"",
    password: "",
    profileUrl: "",
    filename:"",
    role: '4',
    permissions: [],
    userId: "",
    uuid: "",
    userStatus:"1"
}
const AddAccount = ( props ) => {
    
    var  { apps, users }  =  useSelector((state) => state.organization);
    var  { branches, departments }  =  useSelector((state) => state.utilities); 
    const [ selectedPermissions, setSelectedPermissions ] =  React.useState([])
    const [ isLoading, setIsloading] = React.useState(false)
    const [ rowState, setRowState ] = React.useState({
        active: false,
        id:null,
        departmentId:"",
        admin: false,
        created: "",
        email: "",
        firstname: "",
        lastname: "",
        organizationId: "",
        branchId:"",
        password: "",
        profileUrl: "",
        filename:"",
        role: '4',
        permissions: selectedPermissions,
        userId: "",
        uuid: "",
        userStatus:"1"
    })
 
     const appsData = [];
        apps && apps.forEach((s)=>{
        let ser = {value:s.id, label:s.name}
        appsData.push(ser)
    })
  
    const dispatch = useDispatch(); 
    const editRow = ( key, value) => { 
         setRowState( prevState => ({
            ...prevState,
            [key]: value
            })
        ); 
    }

    const validateUser = () => {
        let perm = selectedPermissions?.map((p)=> {return p.value } ) || []
        rowState['permissions'] = perm
        var  { firstname, lastname, email, password, branchId, permissions, departmentId }  = rowState
         if(firstname === ""){
             dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a firstname' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        }
        else if(lastname === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a lastname' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        }
        else if(email === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide an email' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(password === ""){ 
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a password' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(branchId === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please select a branch' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(departmentId === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please select a department' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
        }
        else if(permissions.length === 0){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please add at least one user permission' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
             }, 4000);
         } 
        else{
            const {email } = rowState
            var exists = users?.find(u=>u.email===email)
            if(exists){
                dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'A user with that email already exist' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                 }, 4000);
            }
            else{ 
                addUserToAuth()
            } 
        }
      }
      
      const addUserToAuth = () => {
        setIsloading(true)
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'info', text:'Creating a user account, just a moment...' }})
        dispatch(createFirestoreUser(rowState)).then((res) => { 
           dispatch({type:'ON_UTILITY_SUBMITED'})
           setRowState(DefaultUser)
           dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'User account successfully created' }})
            setTimeout(() => {
                dispatch({type:'HIDE_SWEET_ALERT'})
                setIsloading(false) 
                props.history.push('/utilities/users');
            }, 4000); 
            })
          .catch((e) => { 
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error creating user account' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
              setIsloading(false)  
             }, 5000); 
              
          });  
       }

 
   return (
       
      <div className="container-xxl flex-grow-1 container-p-y">

          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Add New /</span> Account</h4>
  
        <div className="row">
        <div className="col-md-12"> 
            <div className="card mb-4">
            <h5 className="card-header">Profile Details</h5>
            <div className="card-body">
            <Alert/> 
            </div>
            {/* <br/> */}
             {/* <hr className="my-0" /> */}
            <div className="card-body">
                <form id="formAccountSettings">
                <div className="row">
                    <div className="mb-3 col-md-6">
                    <label for="firstName" className="form-label">First Name</label>
                    <input
                        className="form-control"
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={(e)=>editRow("firstname", e.target.value)}
                        value={ rowState.firstname}
                        autoFocus
                    />
                    </div>
                    <div className="mb-3 col-md-6">
                    <label for="lastName" className="form-label">Last Name</label>
                    <input 
                        className="form-control" 
                        type="text" 
                        name="lastName" 
                        id="lastName" 
                        onChange={(e)=>editRow("lastname", e.target.value)}
                        value={ rowState.lastname }
                    />
                    </div>
                    <div className="mb-3 col-md-6">
                    <label for="email" className="form-label">E-mail</label>
                    <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        onChange={(e)=>editRow("email", e.target.value)}
                        value={ rowState.email }
                        placeholder="john.doe@example.com"
                    />
                    </div>
                    <div className="mb-3 col-md-6">
                    <label className="form-label" for="password">Password</label>
                    <input
                        className="form-control"
                        type="password"
                        id="password"
                        name="password"
                        onChange={(e)=>editRow("password", e.target.value)}
                        // Display empty if ther is a long hash and update on change
                        value={ rowState?.password.length > 50 ? '' : rowState.password}
                        autoFocus
                    />
                    </div>

                    <div className="mb-3 col-md-6">
                    <label className="form-label" for="branch">Branch</label>
                    <select id="branch" value={rowState.branchId} onChange={(e)=>editRow("branchId", e.target.value)} className="select2 form-select">
                        <option value="">Select</option>
                        {branches?.map( ({ id, name }) => <option className="" key={id} value={id}>{name}</option> )}
                         
                    </select>
                    </div>
                    <div className="mb-3 col-md-6">
                    <label for="role" className="form-label">Role</label>
                    <select id="role" value={rowState.role} onChange={(e)=>editRow("role", e.target.value)} className="select2 form-select">
                        <option value="" disabled={true}>Select role</option>
                        <option value="1">Admin</option>
                        <option value="2">Branch Manager</option>
                        <option value="3">Supervisor</option>
                        <option value="4">Consultant/ Officer</option>
                    </select>
                    </div>
                    <div className="mb-3 col-md-6">
                    <label className="form-label" for="branch">Department</label>
                    <select id="branch" value={rowState.departmentId} onChange={(e)=>editRow("departmentId", e.target.value)} className="select2 form-select">
                        <option value="">Select</option>
                        {departments?.map( ({ id, name }) => <option className="" key={id} value={id}>{name}</option> )}
                         
                    </select>
                    </div>
                    <div className="mb-3 col-md-6">
                    <label for="role" className="form-label">Account Status</label>
                    <select id="role" slected={rowState.userStatus} onChange={(e)=>editRow("userStatus", e.target.value)} className="select2 form-select">
                        {
                            Constants.STATUS.map((s )=>{  
                                return  <option  value={s.id} >{s.name}</option>
                            })
                        } 
                    </select>
                    </div>
                    
                    <div className="mb-6 col-md-12">
                   
                    <div className="mb-4">
                    <label for="permissions" className="form-label">Apps Permissions</label>
                   <Select
                    // defaultValue={selectedPermissions}
                    // value={selectedPermissions}
                    onChange={(e)=>setSelectedPermissions(e)}
                    options={appsData}
                    isMulti
                    name="services"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  />
                </div>  
                    </div>

                   
                </div>
                <div className="mt-2" >
                    <div disabled={
                        isLoading
                    } onClick={validateUser} style={{}} className="btn btn-primary me-2">Save changes</div>
                     <Link to="/utilities/users"  className="btn btn-outline-secondary">Cancel</Link>
                </div>
                </form>
            </div>
            </div>

 
        </div>
        </div>
     
        </div>
   );
};

export default AddAccount;
