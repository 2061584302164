import { doc, addDoc,  onSnapshot, updateDoc, collection, deleteDoc} from "firebase/firestore";
import { getStorage, ref, deleteObject, getMetadata, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../../utils/firebase";
 


const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()
const { v1: uuidv1 } = require('uuid');

  
export const createForm = (payload) => async (dispatch, getState) => { 
      dispatch({ type: 'ON_DATA_SUBMITING' });
      
      var {  organization, auth, utilities } = getState()
      let st = getState()
       // const upload = await handleFilesUploadOnFirebaseStorage(payload)  
      const organizationObject = organization.organization

     for (var i = 0; i < payload.length; i++) {
      var  originFileOb  =payload[i].name
       var ext = originFileOb.split('.').pop()
      var fileName = uuidv1() + "." + ext
    
        /** @type {any} */
        const metadata = {
        contentType: payload[i].type
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageFunc = getStorage();
      const bucketName = 'rootStorage/uploads/'
      const storageRef = ref(storageFunc, bucketName + fileName);
      const uploadTask = uploadBytesResumable(storageRef, payload[i], metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + parseInt(progress) + '% done');
          switch (snapshot.state) {
            case 'paused':
              // console.log('Upload is paused');
              break;
            case 'running':
              // console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // console.log('Upload error', error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, 
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((mediaUrl) => {
             getMetadata(storageRef)
            .then(async(metadata) => { 
              // var  mediaUpload  = getState()
               var  { fileUpload } = utilities
              var  {title, description} = fileUpload
              var contentType = metadata.contentType
              var fullPath = metadata.fullPath
              var type = metadata.type
              var size = metadata.size
              var filename = metadata.name
              var data = { contentType, fullPath, type, size}
              var now = dayjs().format()
              var created = dayjs(now).unix()
              const uuid = uuidv1();
              var extras = { status:0, indexed:false}
              var payload = { title, description, data, deleted:false, filename, mediaUrl, createdBy:auth.user.uid, extras, organizationId:organizationObject.id, created, uuid }
                try{
                 await addDoc(collection(db, "utilities/data/storage"),  { ...payload } )
                 .then((res) =>{ 
                      return  res })
                  .catch((error) => { 
                    return  error
                  }); 
                } catch (err) { 
                  return  err
                }

               })
               .catch((error) => {
                  // Uh-oh, an error occurred!
               });
 
          });
        }
      );
  
      
     
  }
  };
  
 
         
export const deleteForm = (id, path) => async () => {
  if(id){
    await deleteDoc(doc(db, "utilities/data/storage", id))
    .then(()=>{
      // Create a reference to the file to delete
        const desertRef = ref(storage, path);

        // Delete the file
        deleteObject(desertRef).then(() => {
          // File deleted successfully
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
    }).catch( error => { } )
  };

  }

   const handleFileUploadOnFirebaseStorage = async (file) => {
     
     // // 1. If no file, return
     if (!file) return Promise.reject(false)
 
       return new Promise(async (resolve, reject) => { 
           const bucketName = 'rootStorage/uploads/'
             var ext = file.type.split('/').pop() 
            var fileName = uuidv1() + "." + ext 
              /** @type {any} */
              const metadata = {
                contentType: file.type
              };
               // console.log("====fileName====", file,   )
            console.log("====fileName====", file, fileName, ext )
            // return false
            const storageRef = ref(storage, `${bucketName}/${file}`);
            try {
             const uploadTask = uploadBytesResumable(storageRef, file.name)
             uploadTask.on("state_changed",
             (snapshot) => {
                 const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                 console.log("====progress====", progress )
                },
               (error) => {
                 reject({ status:false, msg: 'Error while uploading files to the server', });
                }, () => { 
                  getMetadata(storageRef)
                  .then((metadata) => {
                    console.log("====metadata====", metadata )
                      //  getDownloadURL(uploadTask.snapshot.ref).then(async(data) => {
                      //     resolve( {url:data , metadata} )  
                      //   })
                        getDownloadURL(metadata.name).then(async(data) => {
                          console.log("====name====", data )
                          resolve( {url:data , metadata} )  
                        })
                     })
                     .catch((error) => {
                      reject({ status:false, msg: 'Error while uploading files to the server', });
                       // Uh-oh, an error occurred!
                     });
               })
             } catch (error) {
               reject({ status:false, msg: 'Error while uploading documents to the server', });
              }  
           }  
    )
  };
  
  const handleFilesUploadOnFirebaseStorage = async (files) => {
    // 1. If no file, return
    if (files.length === 0) return [];

    // 2. Create an array to store all download URLs
    let fileUrls = [];
    // console.log("====handleFilesUploadOnFirebaseStorage====", files.length, files )
    // 3. Loop over all the files
    for (var i = 0; i < files.length; i++) {
        // 3A. Get a file to upload
        const file = files[i].path;

        console.log("====file====", files[i], file )
        // 3B. handleFileUploadOnFirebaseStorage function is in above section
        const downloadFileResponse = await handleFileUploadOnFirebaseStorage(files[i]);
        // 3C. Push the download url to URLs array
        console.log("====downloadFileResponse====", downloadFileResponse )
        fileUrls.push(downloadFileResponse);
    }
    console.log("====fileUrls====", fileUrls )

    return fileUrls;
};

 