import React from "react";
import {  useDispatch } from "react-redux";
import  { deleteForm }  from "../../../../../store/actions/utilities/branch";

const RowActions = ({branchId}) => {
  const dispatch = useDispatch()
  const deleteBranch = () => dispatch(deleteForm(branchId)) 
  const updateBranch = () => dispatch({type:'ON_BRANCH_UPDATE', payload:branchId} )  


  return (
    <div class="d-inline-block text-nowrap">
      <button onClick={updateBranch} class="btn btn-sm btn-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUpdateBrach">
        <i style={{fontSize: 16}} class="bx bx-edit"></i>
      </button>
      <button onClick={deleteBranch} class="btn btn-sm  btn-icon delete-record">
        <i style={{fontSize: 16, color:'red'}} class="bx bx-trash  "></i>
      </button>
      {/* <button class="btn btn-sm btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
        <i style={{fontSize: 16}} class="bx bx-dots-vertical-rounded"></i>
      </button> */}
      <div class="dropdown-menu dropdown-menu-end m-0" style={{}}>
        <div  style={{cursor:"pointer"}} onClick={()=>console.log("View...",branchId)}  class="dropdown-item">View</div>
        <div   style={{cursor:"pointer"}} onClick={()=>console.log("Suspending account...",branchId)} class="dropdown-item">Suspend</div>
      </div>
    </div>
  )
}

 
export default  RowActions
 