import {
    ON_UTILITY_SUBMITED,

    ON_BRANCHES_LOADED,
    ON_BRANCH_UPDATE,
    ON_BRANCH_KEY_UPDATE,

    ON_DEPARTMENTS_LOADED,
     ON_DEPARTMENT_KEY_UPDATED,
    ON_DEPARTMENT_UPDATE,

    ON_DEVICES_LOADED,
     ON_DEVICE_KEY_UPDATED,
    ON_DEVICE_UPDATE,

    ON_STORAGE_LOADED,
    ON_STORAGE__KEY_UPDATE

 } from "../types/utilities/types"


var initialState = {

    // DATA STATUS
    branches        : [],
    departments     : [],
    devices         : [],
    storage         : [],

    // CRUD
    department      : { name:'', branchId:'' , departmentStatus:'1' },
    branch          : { name:'', address:'',  country:'', region:'', branchStatus:'1' },
    device          : { name:'', branchId:'' , description:'', solutionId:'', queueManagement:false, deviceStatus:'1' },
    fileUpload      : { id:null, title:"", description:"", mediaObject:[], mediaUrl:"" }
    
}
  
export default (state = initialState, action) => {
const { type, payload } = action
      switch (type) {

        // UTILITY  clear crud forms
        case ON_UTILITY_SUBMITED:
        return {
            ...state,
            department : { name:'', branchId:'' , departmentStatus:'1' },
            branch     : { name:'', address:'',  country:'', region:'', branchStatus:'1' },
            device     : { name:'', branchId:'' , description:'', solutionId:'', queueManagement:false, deviceStatus:'1' },
            fileUpload : { id:null, title:"", description:"", mediaObject:[], mediaUrl:"" }
        };

     // BRANCH 
        case ON_BRANCHES_LOADED:
            return {
                ...state,
                branches: payload ,
            }; 
        
        case ON_BRANCH_KEY_UPDATE:
             let branchCopy = state.branch
            branchCopy[payload.key] = payload.val 
            return {
                ...state,
                branch: branchCopy
            };

        case ON_BRANCH_UPDATE:
            let  branchToUpdate  = state.branches.find((branch)=>  branch.id === action.payload)
             return {
                ...state,
                branch: branchToUpdate
            };
        
        // Department
        case ON_DEPARTMENTS_LOADED:
             return { 
                ...state, 
                departments: payload
            } 
        
        case ON_DEPARTMENT_UPDATE:
            let  departmentToUpdate  = state.departments.find((department)=>  department.id === action.payload)
                return {
                ...state,
                department: departmentToUpdate
            };
            
        case ON_DEPARTMENT_KEY_UPDATED:
            let  departmentCopy = state.department
            departmentCopy[payload.key] = payload.val 
            return {
                ...state,
                department: departmentCopy
            };
 
        // Devices
        case ON_DEVICES_LOADED:
        return { 
            ...state, 
            devices: payload
        } 
        
        case ON_DEVICE_UPDATE:
             let  deviceToUpdate  = state.devices.find((device)=>  device.id === action.payload)
                 return {
                ...state,
                device: deviceToUpdate
            };
            
        case ON_DEVICE_KEY_UPDATED:
            let  deviceCopy = state.device
            deviceCopy[payload.key] = payload.val 
            return {
                ...state,
                device: deviceCopy
            };

          // STORAGE
          case ON_STORAGE_LOADED:
            return { 
                ...state, 
                storage: payload
            } 
            
       
        case ON_STORAGE__KEY_UPDATE:
            let  storageCopy = state.device
            storageCopy[payload.key] = payload.val 
            return {
                ...state,
                device: storageCopy
            };
        
        case "ON_FILE__KEY_UPDATE":
            let  fileCopy = state.fileUpload
            fileCopy[payload.key] = payload.val 
            console.log("fileCopy===", fileCopy);
            return {
                ...state,
                fileUpload: fileCopy
            };
    

       

    default:
        return state
    }
}
  