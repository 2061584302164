import React from "react";
import { useSelector } from "react-redux";

const Alert = () => {
    var { alert:{ visibility, type, text }} = useSelector((state) => state.appReducer)
    if(!visibility)return null

    return(
      <div className={`alert alert-${type} d-flex `} role="alert">
        <span className={`badge badge-center rounded-pill bg-${type} border-label-${type} p-3 me-2`}><i className="bx bx-error-circle fs-6"></i></span>
        <div className="d-flex flex-column ps-1">
          <h6 className="alert-heading d-flex align-items-center fw-bold mb-1">{type}!!</h6>
          <span>{text}</span>
        </div>
      </div>
    )
  }

export default Alert