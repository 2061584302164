import React  from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment' 
 import MUIDataTable from "mui-datatables";
import RowActions from './RowActions'
 

 const formatBytes = (bytes, decimals, binaryUnits) => {

  if(bytes === 0) { return '0 Bytes' }
  var unitMultiple = (binaryUnits) ? 1024 : 1000; 
  var unitNames = (unitMultiple === 1024) ? // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
      ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']: 
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  return parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)) + ' ' + unitNames[unitChanges];
}

const format = "MMMM DD YYYY" 
 const StorageList = () => {
  const dispatch = useDispatch()
  var columns = [ "Filename", "file type", 'Download', 'size', "uploaded by",  "Created", "Action"];
  var {  users, apps }  =  useSelector((state) => state.organization);
  var {  branches, storage }  =  useSelector((state) => state.utilities);
   var appsData = []
  if(apps!==null){ appsData = (apps && apps.filter(element => element.product )) || [] }
    React.useEffect(()=>{ 
     return () => {
      dispatch({type:'ON_UTILITY_SUBMITED'})
     }
  }, [storage, branches, users, dispatch])

 

    return (
       <>
        <div className="card">  
          <div className="row mx-2 m-4">
            <div className="col-md-8">
              <div className="me-3">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    {(storage && storage?.length) || 0 } total files
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
              
                <div className="dt-buttons">  
                <Link to={"add_media"} className="dt-button add-new btn btn-primary"  >
                  <span><i className="bx bx-plus me-0 me-sm-2"></i><span className="d-none d-lg-inline-block">upload New Files</span></span>
                </Link> 
              </div>
            </div>
          </div>
        </div>
          <div className="card-datatable table-responsive">
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            <MUIDataTable
              title={"Storage"}
              data={
                storage !==null &&  storage.map((media) => { 
                  
                    let created = `${moment(new Date(media.created * 1000).toJSON()).format(format) || 'No date'}`
                    let user =  users && users?.find((u)=>  u.userId===media.createdBy)
                    var fullname = "System"
                    if(user){
                      const { firstname,lastname }  = user
                      fullname = ( firstname   +  ' ' + lastname)
                    } 
                    console.log("media", media);
                    var fileContent = '', contentType = media?.data?.contentType
                    if(contentType==="image/*" || contentType==="image/*" || contentType==="image/*" )
                    {
                      fileContent =<img width={75} src={media.mediaUrl} alt="" type="Image/*"/>
                    }
                    else {
                      fileContent = <video controls="" name="media" width={75}>
                                    <source src={media.mediaUrl} type="video/*"/>
                                </video>
                    }
                    var download = <a download={media.title} href={media.mediaUrl}  target="_blank" title={media.title}> Download </a> 
                  return  [media?.title, contentType, download , formatBytes( media?.data?.size || 0), fullname , created, <RowActions storageId={media.id}/>]                
                }) 

              }
              columns={columns}
              // options={options}
              fixedHeader={true} 
              /> 
            </div>
          </div> 
        </div>

    
       </>
   );
};



export default StorageList
 