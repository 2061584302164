import React from "react";
import { useSelector } from "react-redux";

const DepartmentStatistics = (  ) => {
  var { departments }  =  useSelector((state) => state.utilities);
  var  active  =  departments !== null ? departments?.filter(department=>{ return department.departmentStatus === '1'}).length : 0
  var  inActive  =  departments !== null ? departments?.filter(department=>{ return department.departmentStatus === '2'}).length : 0
  var  pending  =  departments !== null ? departments?.filter(department=>{ return department.departmentStatus === '3'}).length : 0
  var  suspended  =  departments !== null ? departments?.filter(department=>{ return department.departmentStatus === '4'}).length : 0

   return (
       
    <div class="row g-4 mb-4">
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Acive Departments</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{active}</h4>
               </div>
             </div>
            <span class="badge bg-label-success rounded p-2">
              <i class="bx bx-network-chart bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
 
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>InActive Departments</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{inActive}</h4>
               </div>
             </div>
            <span class="badge bg-label-secondary rounded p-2">
              <i class="bx bx-network-chart bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Pending Departments</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{pending}</h4>
               </div>
             </div>
            <span class="badge bg-label-warning rounded p-2">
              <i class="bx bx-network-chart bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start justify-content-between">
            <div class="content-left">
              <span>Suspended Departments</span>
              <div class="d-flex align-items-end mt-2">
                <h4 class="mb-0 me-2">{suspended}</h4>
               </div>
             </div>
            <span class="badge bg-label-danger rounded p-2">
              <i class="bx bx-network-chart bx-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
   );
};

export default DepartmentStatistics;
