import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import  { updateAccountLogo, updateAccount }  from "../store/actions/utilities/organization";
 import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import Select from 'react-select';
import Constants from "../utils/Constants";
import Alert from "../components/common/Alert"

  
const  defailtOrganization  = {
    active: false,
    country: "",
    created: "",
    logoUrl: "",
    id:null,
    name: "",
    logoname:"",
    ownerEmail: "",
    ownerId: "",
    setupDone: false,
    shortName: "",
    accountAdmins:[],
    uuid: "",
  }
const UpdateOrganization = () => {
    
    var  {  users,  organization }  =  useSelector((state) => state.organization);
     let { pageId } = useParams();
    if( pageId!=null) var userId = pageId.split('/').pop()
   
    var user = users && users.find((user)=> { return user.userId === userId })
    const dispatch = useDispatch();
    const [ selectedAdmins, setSelectedPermissions ] =  React.useState([])
    const [ isLoading, setIsloading] = React.useState(false)
    const [ rowState, setRowState ] = React.useState(organization)
    const [ preview, setPreview ] = React.useState('')

    const [file, setFile] = React.useState(""); 
    const userData = [];
    users && users.forEach((a)=>{
     let user = {value:a.userId, label:a.email}
     userData.push(user)
    })
    React.useEffect(() => { 
        var accountAdminValues = []
         if(organization){
            let x = users?.forEach((user)=>{
             let admins = organization?.accountAdmins?.find((a)=>a===user.userId) 
             if(admins===user.userId){ 
               let perm = {value:user?.userId, label:user?.email}
              accountAdminValues.push(perm)  
             }
           })
           if(accountAdminValues.length>0) { 
             setSelectedPermissions(accountAdminValues)
        }  
    }
},[ users, organization]) 

 
    
    const editRow = ( key, value) => { 
         setRowState( prevState => ({
            ...prevState,
            [key]: value
            })
        ); 
    }

    const validateUser = () => {
        let admins = selectedAdmins?.map((p)=> {return p.value } ) || []
        rowState['accountAdmins'] = admins
        var  { name, shortName, ownerEmail,  accountAdmins, }  = rowState
       
         if(name === ""){
             dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide a valid name' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        }
        else if(ownerEmail === ""){
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'warning', text:'Please provide an owner email' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
           }, 4000);
        } 

        else if (file!=='') { 
              setIsloading(true)
            dispatch(updateAccountLogo(file, rowState)).then((res) => { 
                dispatch({type:'ON_UTILITY_SUBMITED'})
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Account successfuly' }})
                setTimeout(() => {
                 dispatch({type:'HIDE_SWEET_ALERT'})
                setIsloading(false) 
                }, 3000); 
                })
              .catch((e) => { 
                 dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error updating account' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                  setIsloading(false)  
                 }, 4000); 
                  
              }); 
        
            
        } 
        else{
             setIsloading(true)
            dispatch(updateAccount(rowState)).then((res) => { 
                dispatch({type:'ON_UTILITY_SUBMITED'})
               dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Account updated successfuly' }})
                setTimeout(() => {
                 dispatch({type:'HIDE_SWEET_ALERT'})
                setIsloading(false) 
                }, 3000); 
                })
              .catch((e) => { 
                 dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error updating account' }})
                setTimeout(() => {
                  dispatch({type:'HIDE_SWEET_ALERT'})
                  setIsloading(false)  
                 }, 4000); 
                  
              }); 
       
        }
      }

      const saveUpload = (e) => {
        e.preventDefault()
        dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'info', text:'uploading new image' }})
        setIsloading(true) 
         dispatch(updateAccountLogo(file, rowState)).then((res) => { 
            dispatch({type:'ON_UTILITY_SUBMITED'})
           dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'image uploaded successfuly' }})
            setTimeout(() => {
             dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false) 
            }, 3000); 
            })
          .catch((e) => { 
            console.log(e);
            dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error uploading image' }})
            setTimeout(() => {
              dispatch({type:'HIDE_SWEET_ALERT'})
              setIsloading(false)  
             }, 4000); 
              
          }); 
      }

    const handleFileuploadChange = (e) => {
        e.preventDefault()
        setFile(e.target.files[0]); 
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const toggleAccountStatus = e => {
         setRowState( prevState => ({
            ...prevState,
            active: !e.target.value
            })
        );
    }

 if(!organization){
    return <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
        <div className="col-md-12">
        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Loading organization...</span></h4>
        </div>
        </div>
    </div>
 }
    
return (
    
    <div className="container-xxl flex-grow-1 container-p-y">

        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Account /</span> Edit</h4>

    <div className="row">
    <div className="col-md-12">
    
        <ul className="nav nav-pills flex-column flex-md-row mb-3">
        <li className="nav-item">
            <a className="nav-link active" href="/"><i className="bx bx-user me-1"></i> Details</a>
        </li>
        <li   className="nav-item" >
            <Link to={`/developers`} className="nav-link" 
            ><i className="bx bx-lock me-1"></i> Developers</Link  >
        </li>
        <li   className="nav-item" >
            <Link to={`/billing`} className="nav-link" 
            ><i className="bx bx-lock me-1"></i> Billing</Link  >
        </li>
        </ul>
        <div className="card mb-4">
        <h5 className="card-header">Update company details </h5>
        <div className="card-body">
        <Alert/>
            <div className="d-flex align-items-start align-items-sm-center gap-4">
                {
                    <img
                    src={preview.length === 0 ? rowState?.logoUrl : preview} 
                    alt={""}
                    className="d-block rounded"
                    style={{width: 100, height:100, backgroundColor:"#f5f5f9", display:"flex"}}
                    id="uploadedAvatar"
                /> 
                }
            
            <div className="button-wrapper">
                <label for="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
                <span className="d-none d-sm-block">Upload new logo</span>
                <i className="bx bx-upload d-block d-sm-none"></i>
                <input
                    type="file"
                    id="upload"
                    className="account-file-input"
                    hidden
                    name="fileurl"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e)=>{ handleFileuploadChange(e) }}
                />
                </label>
                <button type="button" onClick={(e)=>saveUpload(e)} className="btn btn-outline-secondary account-image-reset mb-4">
                <i className="bx bx-reset d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Save</span>
                </button>

                <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 2MB</p>
                
            </div>
            </div>
        </div>
        <br/>
            <hr className="my-0" />
        <div className="card-body">
            <form id="formAccountSettings">
            <div className="row">
                <div className="mb-3 col-md-6">
                <label for="firstName" className="form-label">Organization name</label>
                <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    onChange={(e)=>editRow("name", e.target.value)}
                    value={ rowState?.name}
                    autoFocus
                />
                </div>
                <div className="mb-3 col-md-6">
                <label for="lastName" className="form-label">Short name</label>
                <input 
                    className="form-control" 
                    type="text" 
                    name="lastName" 
                    id="lastName" 
                    onChange={(e)=>editRow("shortName", e.target.value)}
                    value={ rowState?.shortName }
                />
                </div>
                <div className="mb-3 col-md-6">
                <label for="email" className="form-label">Owners E-mail</label>
                <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    onChange={(e)=>editRow("ownerEmail", e.target.value)}
                    value={ rowState?.ownerEmail }
                    placeholder="john.doe@example.com"
                />
                </div>
          

                <div className="mb-3 col-md-6">
                <label for="lastName" className="form-label">Address</label>
                <input 
                    className="form-control" 
                    type="text" 
                    name="address" 
                    id="address" 
                    onChange={(e)=>editRow("address", e.target.value)}
                    value={ rowState?.address }
                />
                </div>
                <div className="mb-3 col-md-6">
                <label for="role" className="form-label">Industry</label>
                <select id="role" value={rowState?.role} onChange={(e)=>editRow("industry", e.target.value)} className="select2 form-select">
                    <option value="" disabled={true}>Industry</option>
                    <option value="1">Agriculture</option>
                    <option value="2">Technology</option>
                    <option value="3">Finance</option>
                    <option value="4">Legal</option>
                    <option value="4">Education</option>
                    <option value="4">Medical</option>
                </select>
                </div>
                
                <div className="mb-3 col-md-6">
                <label className="form-label" for="country">Country</label>
                    <CountryDropdown
                        value={rowState.country || ''}
                        onChange={(e)=>editRow('country', e)}
                        className=" form-select form-select-lg  form-control"
                    />
                </div>            
                
                <div className="mb-6 col-md-12">
                
                <div className="mb-4">
                <label for="permissions" className="form-label">Account Admins</label>
                <Select
                // defaultValue={selectedAdmins}
                value={selectedAdmins}
                onChange={(e)=>setSelectedPermissions(e)}
                options={userData}
                isMulti
                name="services"
                className="basic-multi-select"
                classNamePrefix="select"
                />
            </div>  
                </div>

                
            </div>
            <div className="mt-2" >
                <div disabled={
                    isLoading
                } onClick={validateUser} style={{}} className="btn btn-primary me-2">Save changes</div>
                <Link to="/apps"  className="btn btn-outline-secondary">Cancel</Link>
            </div>
            </form>
        </div>
        </div>


        <div className="card" style={{pointerEvents:"none"}}>
        <h5 className="card-header">Delete Account</h5>
        <div className="card-body">
            <div className="mb-3 col-12 mb-0">
            <div className="alert alert-warning">
                <h6 className="alert-heading fw-bold mb-1">Are you sure you want to delete your account?</h6>
                <p className="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
            </div>
            </div>
            <div id="formAccountDeactivation" >
            <div className="form-check mb-3">
                <input
                className="form-check-input"
                type="checkbox"
                name="accountActivation"
                // value={user.active}
                // disable={!user.admin}
                // onChange={toggleUserStatus}
                id="accountActivation"
                />
                <label className="form-check-label" for="accountActivation">I confirm my account deactivation</label>
            </div>
            {
            rowState.active ?
            <button type="submit" onClick={validateUser}  className="btn btn-danger deactivate-account">Deactivate Account</button>
            :
            <button type="submit" onClick={validateUser}   className="btn btn-seccess deactivate-account">Activate Account</button>
        }
            </div>
        </div>
        </div>

        
    </div>
    </div>
    
    </div>
)};

export default UpdateOrganization;
