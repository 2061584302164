import {
    SHOW_SWEET_ALERT,
    HIDE_SWEET_ALERT,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
} from "../types/appStore/types"


var initialState = { 
    alert: {
        visibility:false,  
        type:'', text:''
    }, 
    popup: { 
        type:'', 
        message:'', 
        extras:{} 
      },
}
  
export default (state = initialState, action) => {
const { type, payload } = action
    switch (type) {
    case SHOW_SWEET_ALERT:
        return { 
            ...state, 
            alert: { 
                visibility:true, 
                type:payload.type, 
                text:payload.text
            } 
        }

    case HIDE_SWEET_ALERT:
        return { 
            ...state, 
            alert:{ 
                visibility:false, 
                type:'', text:''
            }
        }
    
    case SHOW_NOTIFICATION:
        let { type, message, title } = action.payload
        return {
            ...state,
            popup: { type, title, message, show:true }
        };
    
    case HIDE_NOTIFICATION:
        return {
        ...state,
        popup: { type:'', title:'', message:'', show:false }
    };

    default:
        return state
    }
}
  