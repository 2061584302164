import React  from "react";
import { useSelector, useDispatch } from "react-redux"; 
import {  getServices, getBranches, queueTicket} from "../../../../store/actions/queueManagement";
import { Link } from "react-router-dom";

const columns = [ "File ID", 'SIZE', "TYPE", "Status", "Created", "Action"];
const JoinQueue = () => {
  const dispatch = useDispatch()
  const format = "MMMM DD YYYY" 
  var {  users, organizations, branches, services, currentOrganizationId }  =  useSelector((state) => state.organization);
  var {  popup }  =  useSelector((state) => state.appReducer);
   let state  = useSelector((state) => state.queueManagement);
   var { ticket } = useSelector((state) => state.queueManagement); 
  const [ isLoading, setIsloading ] = React.useState(false)
 
  React.useEffect(()=> { 
   
   if(currentOrganizationId){ 
      
      dispatch(getBranches(currentOrganizationId)) 
    dispatch(getServices(currentOrganizationId))
   }
    return () => {
    dispatch({type:'ON_UTILITY_SUBMITED'})
   }
}, [    currentOrganizationId ])


  const onChange = (key, val) => { 
    dispatch({type:'ON_CURRENT_ORGANIZATIONID', payload:val}) 
     onTicketChange("organizationId", val)
    
  }

  const onTicketChange = (key, val) => { 
    dispatch({type:'ON_TICKET_KEY_UPDATED', payload:  {key, val}}) 
  }

  const handleSubmit = async ()  =>{
           setIsloading(true)
         await dispatch(queueTicket(ticket)).then((res) => { 
              dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'Queue created' }})
             setTimeout(() => {
                setIsloading(false)  
            dispatch({type:'HIDE_SWEET_ALERT'})
            }, 3000) 
            })
          .catch((e) => { 
          dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'danger', text:'Error joining queue' }})
          setTimeout(() => {
            dispatch({type:'HIDE_SWEET_ALERT'})
            setIsloading(false)   
          }, 4000);  
        }); 
  }

  const clearTicket = () => {
    dispatch({type:'ON_TICKET_CLEAR'}) 
  }

 

    return (
       
    <div className="card">  
      <div className="row mx-2 m-4 mb-0">
        <div className="col-md-8">
          <div className="me-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <h3>
                Queue wizard
                
              </h3>
            </div>
          </div>
        </div> 
    </div> 
      { popup.show ? 
        <div className="m-4">
            <div class="alert alert-danger alert-dismissible" role="alert">
                <h6 class="alert-heading d-flex align-items-center fw-bold mb-1">Servece not available at this branch!!</h6>
                <p class="mb-0">{popup.message}</p>
                <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={clearTicket} aria-label="Close"></button>
            </div>
        </div>
      : null
      }

    <div className="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">  
      <div className="row mx-2 m-4">
      <div className="mb-4">
        {
           ticket?.number ? 
           <div className="m-4">
          <div class="alert alert-success alert-dismissible" role="alert">
              <h6 class="alert-heading d-flex align-items-center fw-bold mb-1">Your ticket number: {ticket?.number}</h6>
              <p class="mb-0">You will recieve a notification shortly</p>
 
                </div> 
               <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <Link  to={"/queue"} className="btn btn-primary " onClick={clearTicket}  
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>  View queue </span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </Link>
          </div>
      </div>
      : null
 
      }

      </div>
      {
         ticket?.number ? null :
         <>
       <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Organization to join</label>
            <select id="user-plan"   onChange={(e)=>onChange('updateOrganizationId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select organization</option>
            {
                organizations && organizations?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Branch</label>
            <select id="user-plan"    onChange={(e)=>onTicketChange('branchId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select branch</option>
            {
                branches && branches?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 

          <div className="mb-4">
            <label className="form-label" htmlFor="user-plan"> Service</label>
            <select id="user-plan"    onChange={(e)=>onTicketChange('serviceId', e.target.value)} className="form-select">
            <option  selected disabled={true} >Select service</option>
            {
                services && services?.map((el )=>{  
                  return  <option  value={el.id} >{el.name}</option>
                })
              } 
            </select> 
          </div> 
          <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"> 
                <button className="btn btn-primary " onClick={handleSubmit} disabled={
                  isLoading || 
                  ticket.organizationId ==="" ||
                  ticket.branchId ==="" ||
                  ticket.serviceId ==="" 
                  } 
                  style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>{isLoading ? "Queueing, please wait" : "Queue"}</span>
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
 
                </div> 
         </>
      } 
          
     </div> 
  
 
      </div>
    </div>
 

    </div>
   );
};



export default JoinQueue
 