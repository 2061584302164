import React from "react";
import { Footer } from "../components/footer/Footer";
import { AsideContent } from "../components/aside/AsideContent";
import { NavContent } from "../components/nav/Navigation";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import  { fetchUser }  from "../store/actions/user";
 
const Layout = (props) => { 

  const dispatch = useDispatch();
  var { user : currentUser } = useSelector((state) => state.auth); 
  var { showMainMenu } = useSelector((state) => state.organization);
   var toggleMainMenu = () => { dispatch({type:'TOGGLE_MAIN_MENU'})}

  React.useState(()=>{ 
      if (currentUser) dispatch(fetchUser(currentUser))
  },[currentUser ])

  if(!currentUser?.uid){
    return(<Redirect to={ '/login' } />)
  }

  return (
    // <!-- Layout wrapper -->
    <div className={`layout-wrapper layout-content-navbar layout-menu-fixed ${ showMainMenu ? 'layout-menu-expanded' : ''}` }>
      <div className="layout-container">

        {/* <!-- Menu --> */} 
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <AsideContent/>
        </aside>
        {/* <!-- / Menu --> */}

        {/* <!-- Layout container --> */}
        <div className="layout-page">

          {/* <!-- Navbar --> */}
          <nav
            className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
          >
            <NavContent/>
           </nav> 
          {/* <!-- / Navbar --> */}

          {/* <!-- Content wrapper --> */}
          <div className="content-wrapper">
            {/* <!-- Content --> */}

            <div className="container-xxl flex-grow-1 container-p-y">
                  { props.children } 
            </div>


             {/* <!-- Footer --> */}
             <Footer/>
            {/* <!-- / Footer --> */}

            <div className="content-backdrop fade"></div>
          </div> 
        </div>
         <div className="layout-overlay layout-menu-toggle"  onClick={()=>toggleMainMenu()}></div>
        <div className="drag-target"></div>
      </div>

    </div>
   );
};

export default Layout;
